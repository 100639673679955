import { Plugin, PluginKey } from 'prosemirror-state'

export const editorOptionsKey = new PluginKey('editorOptions')

export const editorOptionsPlugin = new Plugin({
  key: editorOptionsKey,
  state: {
    init(config, _state) {
      return config.editorOptions
    },

    apply(tr, value, _oldState, _newState) {
      return value
    },
  },
})
