import $ from 'jquery'
import crel from 'crelt'
import { Dialog } from '../dialog'
import { addClass, hasClass, removeClass } from '../../../../common/helpers/dom'
import { documentFromHTMLString } from '../utils'
import { ResizableView } from './resizable_view'

const isSimpleroScaledImage = (url) => url.includes('/scaled_image/')

const assetIdFromUrl = (url) => {
  const match = url.match(/scaled_image\/(\d+)\//)
  return match && match[1]
}

export class ImageView extends ResizableView {
  constructor(node, view, getPos) {
    super()

    this.node = node
    this.view = view
    this.getPos = getPos

    const {
      src,
      alt,
      width,
      height,
      videoAssetId,
      audioAssetId,
      assetId,
      alignment,
      embeddedVideo,
      autoplay,
    } = node.attrs

    const imageAttributes = {
      src,
      alt,
      align: alignment === 'center' ? 'middle' : alignment,
      class: 'image-node',
      'data-asset-id': assetId,
      'data-embedded-video': embeddedVideo,
      'data-autoplay': autoplay,
    }

    if (width) imageAttributes.width = width
    if (height) imageAttributes.height = height

    this.dom = crel(
      'div',
      { class: `prosemirror-editor-image aligned-${alignment || 'none'}` },
      [crel('img', imageAttributes), this.imageOverlay()]
    )

    this.setupResize({
      container: this.dom,
      resizeStrategy: 'PROPORTIONAL',
    })

    this.dom.querySelector('button').addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()

      const button = e.target
      // Get discovery of asset manager to work first
      if (hasClass(button, 'asset-manager')) {
        window.top.Simplero.AssetsDialog.open({
          mode: 'html',
          media_type: videoAssetId ? 'video' : audioAssetId ? 'audio' : null,
          callback: (_, html) => {
            const fragment = documentFromHTMLString(html)
            view.dispatch(
              view.state.tr.replaceWith(
                getPos(),
                getPos() + node.nodeSize,
                fragment
              )
            )
          },
        })
      } else {
        // Edit image
        window.top.Simplero.editImageAsPublicAsset(src, (url) => {
          const newAttrs = { ...node.attrs, src: url }
          view.dispatch(
            view.state.tr.replaceWith(
              getPos(),
              getPos() + node.nodeSize,
              node.type.create(newAttrs)
            )
          )
        })
      }
    })

    this.dom.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      addClass(this.dom, 'selected')
    })

    this.dom.addEventListener('dblclick', (e) => {
      // Allow editing attributes here
      const attrs = node.attrs

      const callback = (values) => {
        this.changeAttributes(values)
      }

      const fields = [
        {
          type: 'text',
          name: 'src',
          label: 'Location',
          value: attrs && attrs.src,
        },
        {
          type: 'text',
          name: 'alt',
          label: 'Description',
          value: attrs && attrs.alt,
        },
        {
          type: 'number',
          name: 'width',
          label: 'Width',
          value: attrs && attrs.width,
        },
        {
          type: 'number',
          name: 'height',
          label: 'Height',
          value: attrs && attrs.height,
        },
      ]

      new Dialog('image', 'Image', fields, callback).show()
    })

    window.addEventListener('click', (e) => {
      removeClass(this.dom, 'selected')
    })
  }

  get attrs() {
    return this.node.attrs
  }

  changeAttributes(newAttributeValues) {
    const { node, view, getPos } = this
    const attrs = node.attrs
    const src = attrs.src
    const { width, height } = newAttributeValues
    const newAttrs = { ...attrs, ...newAttributeValues }

    const replaceAttributes = (newSrc) => {
      const imageAttrs = newSrc ? { ...newAttrs, src: newSrc } : newAttrs
      view.dispatch(
        view.state.tr.replaceWith(
          getPos(),
          getPos() + node.nodeSize,
          view.state.schema.nodes.image.create(imageAttrs)
        )
      )
    }

    // Has been resized?
    if (
      (newAttrs.width !== attrs.width || newAttrs.height !== attrs.height) &&
      isSimpleroScaledImage(attrs.src) &&
      assetIdFromUrl(attrs.src)
    ) {
      if (isSimpleroScaledImage(src) && assetIdFromUrl(src)) {
        $.sajax({
          url: `/admin/assets/${assetIdFromUrl(
            src
          )}/scaled_image_url?scaled_image_url=${encodeURIComponent(
            src
          )}&width=${width}&height=${height}`,
          type: 'GET',
          dataType: 'json',
          error: replaceAttributes,
          success: (data) => replaceAttributes(data.url),
        })
      }
    } else {
      replaceAttributes()
    }
  }

  resized({ width, height }) {
    this.changeAttributes({ width, height })
  }

  imageOverlay() {
    let buttonText, buttonIcon, buttonKlass, buttonTitle

    if (this.attrs.videoAssetId) {
      buttonText = 'Replace'
      buttonIcon = 'film'
      buttonKlass = 'asset-manager'
    } else if (this.attrs.audioAssetId) {
      buttonText = 'Replace'
      buttonIcon = 'headphones'
      buttonKlass = 'asset-manager'
    } else {
      buttonText = 'Image editor'
      buttonIcon = 'image'
      buttonKlass = 'image-editor'
      buttonTitle = 'Launch image editor'
    }

    const button = crel(
      'button',
      {
        class: `prosemirror-image-overlay__button ${buttonKlass}`,
        title: buttonTitle,
      },
      [buttonText, crel('i', { class: `fa fa-${buttonIcon}` })]
    )
    return crel(
      'div',
      { class: 'prosemirror-image-overlay', id: 'prosemirror-image-overlay' },
      button
    )
  }
}
