import crel from 'crelt'
import { fontAwesomeIcon } from '../utils/icons'
import { Plugin } from 'prosemirror-state'
import {
  markActive,
  linkAttributesFromSelectedMark,
  removeMarkInPosition,
} from '../utils'
import tippy from 'tippy.js'

import { showSimpleroLinkDialog } from '../menubar'

class LinkInfoDisplayBox {
  constructor(view) {
    this.view = view
    this.classPrefix = 'ProseMirror-link-display-box'
    this.makeBox()

    view.dom.addEventListener('scroll', this.update.bind(this))
    document.addEventListener('scroll', this.hide.bind(this))
  }

  makeBox() {
    const closeButton = fontAwesomeIcon('xmark').dom
    const editButton = crel('a', { class: 'btn btn-primary' }, 'Edit')
    const removeButton = crel('a', { class: 'btn btn-default' }, 'Remove')
    const linkContainer = crel(
      'div',
      { class: this.classFor('link-container') },
      ''
    )
    const textContainer = crel(
      'div',
      { class: this.classFor('text-container') },
      ''
    )

    closeButton.addEventListener('click', (e) => {
      this.hide()
    })

    editButton.addEventListener('click', (e) => {
      e.stopImmediatePropagation()

      const { state, dispatch } = this.view
      this.hide()
      showSimpleroLinkDialog(state, dispatch)
    })

    removeButton.addEventListener('click', (e) => {
      const { state, dispatch } = this.view

      this.hide()
      removeMarkInPosition(state, dispatch, state.schema.marks.link)
    })

    const container = crel('div', { class: this.classFor('container') }, [
      textContainer,
      closeButton,
      linkContainer,
      crel('div', { class: this.classFor('actions') }, [
        editButton,
        removeButton,
      ]),
    ])

    this.container = container
    this.linkContainer = linkContainer
    this.textContainer = textContainer

    this.tippy = tippy(document.createElement('div'), {
      content: this.container,
      detachOnUnmount: false,
      trigger: 'manual',
      hideOnClick: true,
      appendTo: () => document.body,
      duration: 0,
      offset: [-50, 10],
      placement: 'bottom-start',
      interactive: true,
    })
  }

  classFor(suffix) {
    return `${this.classPrefix}__${suffix}`
  }

  hide() {
    this.tippy.hide()
  }

  positionAndDisplayBox(view) {
    const state = view.state
    const { from } = state.selection
    const start = view.coordsAtPos(from)
    const editorRect = view.dom.getBoundingClientRect()

    // Check if the coordinates are within the visible area of the editor
    if (start.top < editorRect.top || start.bottom > editorRect.bottom) {
      this.hide()
      return
    }

    const rect = {
      top: start.top,
      bottom: start.bottom,
      left: start.left,
      right: start.right,
      width: start.right - start.left,
      height: start.bottom - start.top,
    }

    this.tippy.setProps({ getReferenceClientRect: () => rect })
    this.tippy.show()
  }

  // This gets called on scroll event
  update(_event) {
    let state = this.view.state

    // Text highlighted. Will be weird if both this and context menu shows up.
    if (!state.selection.empty) {
      this.hide()
      return
    }

    // cursor is not on a link
    if (!markActive(state, state.schema.marks.link)) {
      this.hide()
      return
    }

    // When cursor it at the edge of a link, this will return null
    const linkAttrs = linkAttributesFromSelectedMark(state)
    const href = linkAttrs?.selectedAnchor?.href
    if (!href) {
      this.hide()
      return
    }

    // attach attributes
    this.linkContainer.innerHTML = `<a href="${href}" target="_blank">${href}</a>`
    this.textContainer.textContent = linkAttrs.selectedText

    this.positionAndDisplayBox(this.view)
  }
}

export function linkInfoDisplayBoxPlugin() {
  return new Plugin({
    view(editorView) {
      return new LinkInfoDisplayBox(editorView)
    },
  })
}
