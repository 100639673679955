import crel from 'crelt'
import { Dialog } from '../dialog'

export class AnchorView {
  constructor(node, view, getPos) {
    this.dom = crel('a', { id: node.attrs.id, class: 'anchor-node' }, '')

    this.dom.addEventListener('click', (e) => {
      const callback = (values) => {
        if (values.id) {
          view.dispatch(
            view.state.tr.replaceWith(
              getPos(),
              getPos() + node.nodeSize,
              view.state.schema.nodes.anchor.create({ id: values.id })
            )
          )
        }
      }

      const fields = [
        {
          name: 'id',
          type: 'text',
          label: 'Id',
          value: node.attrs && node.attrs.id,
        },
      ]

      new Dialog('anchor', 'Anchor', fields, callback).show()
    })
  }
}
