import crel from 'crelt'

const DEFAULT_RESIZE_STRATEGY = 'WIDTH'

const Direction = {
  NORTH_EAST: 1,
  SOUTH_EAST: 2,
  NORTH_WEST: 3,
  SOUTH_WEST: 4,
}

// Possible resize strategies
// 1. WIDTH
// 2. PROPORTIONAL
export class ResizableView {
  setupResize({ container, resizeStrategy }) {
    this.resizeContainer = container
    this.resizePlaceholder = container.appendChild(
      crel('div', { class: 'ProseMirror-resize-placeholder' })
    )
    this.resizeStrategy = resizeStrategy || DEFAULT_RESIZE_STRATEGY
    this.setupResizeHandles()
  }

  resetDrag() {
    this.dragging = false
    this.resizePlaceholder.style.display = 'none'
    this.resizePlaceholder.style.left = 0
    this.resizePlaceholder.style.right = 0
    this.startRect = null
    this.handlePosition = null
  }

  startDrag(handlePosition) {
    this.dragging = true
    this.dragWidth = null
    this.startRect = this.resizeContainer.getBoundingClientRect()
    this.handlePosition = handlePosition
    this.resizePlaceholder.style.display = 'block'

    if (this.resizeStrategy === 'PROPORTIONAL') {
      if (!this.draggingEastHandles()) {
        this.resizePlaceholder.style.left = 'initial'
        this.resizePlaceholder.style.right = 0
      }

      if (this.draggingNorthHandles()) {
        this.resizePlaceholder.style.bottom = 0
        this.resizePlaceholder.style.top = 'initial'
      } else {
        this.resizePlaceholder.style.top = 0
        this.resizePlaceholder.style.bottom = 'initial'
      }
    }
  }

  move(el, handlePosition) {
    el.addEventListener('mousedown', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.startDrag(handlePosition)
    })

    window.document.addEventListener('mousemove', (e) => {
      if (this.dragging) {
        e.preventDefault()
        e.stopPropagation()
        this.updatePlaceholderSize(e)
      }
    })

    // Allow dragging to continue and end outside the editor
    window.document.addEventListener('mouseup', (e) => {
      if (this.dragging) {
        const rect = this.resizePlaceholder.getBoundingClientRect()

        this.resized({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        })

        this.resetDrag()
      }
    })
  }

  updatePlaceholderSize(e) {
    if (this.resizeStrategy === 'WIDTH') {
      if (this.draggingEastHandles()) {
        this.resizePlaceholder.style.right =
          (e.clientX - (this.startRect.x + this.startRect.width)) * -1 + 'px'
      } else {
        this.resizePlaceholder.style.left = e.clientX - this.startRect.x + 'px'
      }
    } else if (this.resizeStrategy === 'PROPORTIONAL') {
      if (this.dragWidth === null) {
        this.dragWidth = this.startRect.width
      }

      if (this.draggingEastHandles()) {
        const newWidth = e.clientX - this.startRect.x
        const newHeight = (newWidth / this.dragWidth) * this.startRect.height
        this.resizePlaceholder.style.width = newWidth + 'px'
        this.resizePlaceholder.style.height = newHeight + 'px'
      } else {
        const newWidth = this.startRect.x + this.startRect.width - e.clientX
        const newHeight = (newWidth / this.dragWidth) * this.startRect.height
        this.resizePlaceholder.style.width = newWidth + 'px'
        this.resizePlaceholder.style.height = newHeight + 'px'
      }
    }
  }

  draggingEastHandles() {
    return (
      this.handlePosition === Direction.NORTH_EAST ||
      this.handlePosition === Direction.SOUTH_EAST
    )
  }

  draggingNorthHandles() {
    return (
      this.handlePosition === Direction.NORTH_EAST ||
      this.handlePosition === Direction.NORTH_WEST
    )
  }

  draggingSouthHandles() {
    return !this.draggingNorthHandles()
  }

  dragNorthEastHandle(el) {
    this.move(el, Direction.NORTH_EAST)
  }

  dragSouthEastHandle(el) {
    this.move(el, Direction.SOUTH_EAST)
  }

  dragNorthWestHandle(el) {
    this.move(el, Direction.NORTH_WEST)
  }

  dragSouthWestHandle(el) {
    this.move(el, Direction.SOUTH_WEST)
  }

  setupResizeHandles() {
    this.ne_handle = this.resizeContainer.appendChild(
      crel('div', { class: 'ProseMirror-resize-handle resize-handle-ne' }, '')
    )
    this.se_handle = this.resizeContainer.appendChild(
      crel('div', { class: 'ProseMirror-resize-handle resize-handle-se' }, '')
    )
    this.dragNorthEastHandle(this.ne_handle)
    this.dragSouthEastHandle(this.se_handle)

    this.nw_handle = this.resizeContainer.appendChild(
      crel('div', { class: 'ProseMirror-resize-handle resize-handle-nw' }, '')
    )
    this.sw_handle = this.resizeContainer.appendChild(
      crel('div', { class: 'ProseMirror-resize-handle resize-handle-sw' }, '')
    )
    this.dragNorthWestHandle(this.nw_handle)
    this.dragSouthWestHandle(this.sw_handle)
  }
}
