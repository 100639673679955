import crel from 'crelt'
import { stylesForNode } from '../utils'
import { findTable } from '../utils/find_table'
import { toggleClass } from '../../../../common/helpers/dom'
import { ResizableView } from './resizable_view'

// Copy the constructor from prosemirror-tables version of TableView
export class TableView extends ResizableView {
  constructor(node, cellMinWidth, view) {
    super()

    this.node = node
    this.cellMinWidth = cellMinWidth

    this.dom = crel('div', { class: 'tableWrapper' })
    this.tableContainer = this.dom.appendChild(
      crel('div', { class: 'tableContainer' })
    )
    this.table = this.tableContainer.appendChild(
      document.createElement('table')
    )
    this.view = view

    this.setupResize({
      container: this.tableContainer,
      resizeStrategy: 'WIDTH',
    })

    this.setTableAttributes()
    this.setupTableEvents()

    this.colgroup = this.table.appendChild(document.createElement('colgroup'))
    updateColumns(node, this.colgroup, this.table, cellMinWidth)
    this.contentDOM = this.table.appendChild(document.createElement('tbody'))
  }

  resized({ width, _height }) {
    const {
      state: { selection },
      dispatch,
      state,
    } = this.view
    const tr = state.tr
    const table = findTable(selection)

    const attrs = { ...table.node.attrs, width: width }
    tr.setNodeMarkup(table.pos - 1, table.node.type, attrs)

    tr.scrollIntoView()
    dispatch(tr)
  }

  setupTableEvents() {
    this.view.dom.addEventListener('click', (e) => {
      const {
        state: { selection },
      } = this.view
      const table = findTable(selection)
      if (table && table.node == this.node) {
        toggleClass(this.dom, 'focussed', true)
      } else {
        toggleClass(this.dom, 'focussed', false)
      }
    })
  }

  setTableAttributes() {
    const node = this.node
    const styleAttributes = stylesForNode(node)
    const tableAttributes = {
      ...styleAttributes,
      cellpadding: node.attrs.cellpadding,
      cellspacing: node.attrs.cellspacing,
    }
    Object.entries(tableAttributes).map(([key, value]) => {
      this.table.setAttribute(key, value)
    })
  }

  update(node) {
    if (this.dragging) return false
    if (node.type != this.node.type) return false

    this.node = node
    this.setTableAttributes()
    updateColumns(node, this.colgroup, this.table, this.cellMinWidth)

    return true
  }

  ignoreMutation(record) {
    return (
      record.type == 'attributes' &&
      (record.target == this.table || this.colgroup.contains(record.target))
    )
  }
}

export function updateColumns(
  node,
  colgroup,
  table,
  cellMinWidth,
  overrideCol,
  overrideValue
) {
  let totalWidth = 0,
    fixedWidth = true
  let nextDOM = colgroup.firstChild,
    row = node.firstChild
  for (let i = 0, col = 0; i < row.childCount; i++) {
    let { colspan, colwidth } = row.child(i).attrs
    for (let j = 0; j < colspan; j++, col++) {
      let hasWidth =
        overrideCol == col ? overrideValue : colwidth && colwidth[j]
      let cssWidth = hasWidth ? hasWidth + 'px' : ''
      totalWidth += hasWidth || cellMinWidth
      if (!hasWidth) fixedWidth = false
      if (!nextDOM) {
        colgroup.appendChild(document.createElement('col')).style.width =
          cssWidth
      } else {
        if (nextDOM.style.width != cssWidth) nextDOM.style.width = cssWidth
        nextDOM = nextDOM.nextSibling
      }
    }
  }

  while (nextDOM) {
    let after = nextDOM.nextSibling
    nextDOM.parentNode.removeChild(nextDOM)
    nextDOM = after
  }
}
