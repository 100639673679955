import crel from 'crelt'
import { addClass, removeClass, hasClass } from '../../../../common/helpers/dom'
import { Plugin } from 'prosemirror-state'

class FloatingContextMenu {
  constructor(view, menuItems) {
    this.view = view
    this.menuItems = menuItems
    this.rendered = []
    this.makeMenubar()
    this.update(view, null)
    this.lastSelection = null

    view.dom.addEventListener('scroll', (e) => {
      this.update(view, null, true)
    })
  }

  makeMenubar() {
    const menuContents = []

    this.menuItems.forEach((sublist, index) => {
      sublist.forEach((menuItem) => {
        // Get the dom element;
        const { dom, update } = menuItem.render(this.view)

        const el = crel('div', { class: 'ProseMirror-context-menu__item' }, [
          dom,
        ])

        el.addEventListener('click', () => {
          menuItem.run &&
            menuItem.run(this.view.state, this.view.dispatch, this.view)
        })

        menuContents.push(el)

        this.rendered.push({
          el,
          dom,
          update,
          menuItem,
        })
      })

      // Push a separator here
      if (index != this.menuItems.length - 1) {
        menuContents.push(crel('div', { class: 'ProseMirror-menuseparator' }))
      }
    })

    this.menubar = crel(
      'div',
      { class: 'ProseMirror-context-menu hidden' },
      menuContents
    )

    this.view.dom.parentNode.appendChild(this.menubar)
  }

  isMenuItemActive(menuItem) {
    return (
      menuItem.spec &&
      menuItem.spec.active &&
      typeof menuItem.spec.active == 'function' &&
      menuItem.spec.active(this.view.state)
    )
  }

  checkActiveItems() {
    this.rendered.forEach(({ menuItem, dom }) => {
      if (this.isMenuItemActive(menuItem)) {
        addClass(dom, 'ProseMirror-menu-active')
      } else {
        removeClass(dom, 'ProseMirror-menu-active')
      }
    })
  }

  sameAsLastSelection(selection) {
    if (!this.lastSelection) {
      return false
    }

    return (
      this.lastSelection.head == selection.head &&
      this.lastSelection.anchor == selection.anchor
    )
  }

  update(view, lastState = null, ignoreLastSelectionCheck = false) {
    let state = view.state
    // Don't do anything if the document/selection didn't change
    if (
      lastState &&
      lastState.doc.eq(state.doc) &&
      lastState.selection.eq(state.selection) &&
      !hasClass(this.menubar, 'hidden')
    ) {
      return
    }

    const isHorizontalRule =
      state.selection.node &&
      state.selection.node.type === state.schema.nodes.horizontal_rule

    // Hide the tooltip if the selection is empty
    if (state.selection.empty || isHorizontalRule) {
      addClass(this.menubar, 'hidden')
      return
    }

    removeClass(this.menubar, 'hidden')

    // Run before any other checks, if the state of any of the menu items have changed. Ex: Highlight bold item, if selection is bold
    this.checkActiveItems()

    // When the alignment of text is being changed, its mildly annoying that the context menu box
    // changes its position too. So if the selection hasn't changed since the last time, don't update
    // menu box position.
    if (
      !ignoreLastSelectionCheck &&
      this.sameAsLastSelection(state.selection) &&
      !state.selection.node
    ) {
      return
    }

    // Set the last selection
    this.lastSelection = state.selection

    let { from, to } = state.selection
    let offsetParent = this.menubar.offsetParent

    if (!offsetParent) return

    let start = view.coordsAtPos(from),
      end = view.coordsAtPos(to)
    let box = offsetParent.getBoundingClientRect()

    // There's a scrollbar and selected item is invisible
    if (start.top < box.top || start.top > box.bottom) {
      addClass(this.menubar, 'hidden')
      return
    }

    let bottom = box.bottom - start.top
    // 40 = height of context menu
    // 40 = menubar height
    // 10 = some padding
    // Total: 90
    bottom = bottom >= box.height - 90 ? box.bottom - start.bottom - 50 : bottom
    bottom = bottom < 0 ? 0 : bottom

    let left = Math.max((start.left + end.left) / 2, start.left + 3)

    this.menubar.style.left = left - box.left + 'px'
    this.menubar.style.bottom = bottom + 'px'
  }
}

export function textHighlightContextMenu(menuItems) {
  return new Plugin({
    view(editorView) {
      return new FloatingContextMenu(editorView, menuItems)
    },
  })
}
