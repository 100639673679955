import { Schema } from 'prosemirror-model'
import { orderedList, bulletList, listItem } from 'prosemirror-schema-list'
import { getStylePropertyValue, stylesForNode } from '../utils'
import { tableNodes } from 'prosemirror-tables'

const blockquoteDOM = ['blockquote', 0],
  hrDOM = ['hr'],
  brDOM = ['br']

const headingAttributes = {
  alignment: true,
  indent: true,
  fontSize: true,
  lineHeight: true,
  fontFamily: true,
  fontColor: true,
  fontBackgroundColor: true,
}

const paddingStep = 30

function getTableNodes() {
  let tn = tableNodes({
    tableGroup: 'block',
    cellContent: 'block+',
    cellAttributes: {
      background: {
        default: null,
        getFromDOM(dom) {
          return dom.style.backgroundColor || null
        },
        setDOMAttr(value, attrs) {
          if (value)
            attrs.style = (attrs.style || '') + `background-color: ${value};`
        },
      },
    },
  })

  tn.table_cell.toDOM = (node) => {
    const attrs = { ...node.attrs }
    if (node.attrs.colwidth) {
      attrs['data-colwidth'] = node.attrs.colwidth
      attrs.style = `width: ${node.attrs.colwidth}px`
    }
    return ['td', attrs, 0]
  }

  tn.table_cell.parseDOM = [
    {
      tag: 'td',
      getAttrs(cell) {
        const attrs = {}

        const width = getStylePropertyValue(cell, 'width')
        if (width) {
          attrs.colwidth = parseInt(width.replace('px', ''))
        }

        return attrs
      },
    },
  ]

  tn.table.attrs = {
    width: { default: null },
    height: { default: null },
    cellpadding: { default: null },
    cellspacing: { default: null },
    border: { default: null },
    borderColor: { default: null },
    backgroundColor: { default: null },
  }

  tn.table.parseDOM = [
    {
      tag: 'table',
      getAttrs(node) {
        let borderWidth = getStylePropertyValue(node, 'border-width')
        if (!borderWidth && getStylePropertyValue(node, 'border')) {
          borderWidth = getStylePropertyValue(node, 'border').split(/\s+/)[0]
        }
        borderWidth = borderWidth
          ? parseInt(borderWidth.replace('px', ''))
          : null

        let borderColor = getStylePropertyValue(node, 'border-color')
        if (!borderColor && getStylePropertyValue(node, 'border')) {
          borderColor = getStylePropertyValue(node, 'border').split(/\s+/)[2]
        }

        const attrs = {
          width: getStylePropertyValue(node, 'width')
            ? parseInt(String(getStylePropertyValue(node, 'width')))
            : null,
          height: getStylePropertyValue(node, 'height')
            ? parseInt(String(getStylePropertyValue(node, 'height')))
            : null,
          cellpadding: node.getAttribute('cellpadding'),
          cellspacing: node.getAttribute('cellspacing'),
          border: borderWidth,
          borderColor: borderColor,
          backgroundColor: getStylePropertyValue(node, 'background-color'),
        }
        return attrs
      },
    },
  ]

  tn.table.toDOM = (node) => {
    const styleAttributes = stylesForNode(node)
    const tableAttributes = {
      ...styleAttributes,
      cellpadding: node.attrs.cellpadding,
      cellspacing: node.attrs.cellspacing,
    }
    return ['table', tableAttributes, 0]
  }

  return tn
}

const attributesFromDOM = (defaultAttributes, allowedAttributes) => {
  return (node) => {
    const extractedAttributes = {}
    ;[
      ['fontColor', 'color'],
      ['fontBackgroundColor', 'background-color'],
      ['fontSize', 'font-size'],
      ['alignment', 'text-align'],
      ['fontFamily', 'font-family'],
    ].forEach(([attrName, styleAttr]) => {
      if (allowedAttributes[attrName]) {
        const value = getStylePropertyValue(node, styleAttr)
        if (value) {
          extractedAttributes[attrName] = value
        }
      }
    })

    if (allowedAttributes.indent) {
      let value = parseInt(
        String(getStylePropertyValue(node, 'padding-left')).replace('px', '')
      )
      if (value) {
        extractedAttributes.indent = Math.floor(value / paddingStep)
      }
    }

    return { ...defaultAttributes, ...extractedAttributes }
  }
}

function attachClass(currentClass, ...additionalClasses) {
  const classNames = [currentClass].filter((v) => !!v)
  additionalClasses.forEach((klass) => {
    if (!classNames.includes(klass)) {
      classNames.push(klass)
    }
  })
  return classNames.join(' ')
}

// Modified version of prosemirror-schema-basic
export const nodes = {
  doc: {
    content: 'block+',
  },

  paragraph: {
    content: 'inline*',
    attrs: {
      alignment: { default: null },
      indent: { default: null },
      fontSize: { default: null },
      fontFamily: { default: null },
      lineHeight: { default: null },
      fontColor: { default: null },
      fontBackgroundColor: { default: null },
    },
    group: 'block',
    parseDOM: [
      {
        tag: 'p',
        getAttrs: attributesFromDOM(
          {},
          {
            alignment: true,
            indent: true,
            fontSize: true,
            fontFamily: true,
            fontColor: true,
            fontBackgroundColor: true,
          }
        ),
      },
    ],
    toDOM(node) {
      return ['p', stylesForNode(node), 0]
    },
  },

  blockquote: {
    content: 'block+',
    group: 'block',
    defining: true,
    parseDOM: [{ tag: 'blockquote' }],
    toDOM() {
      return blockquoteDOM
    },
  },

  horizontal_rule: {
    group: 'block',
    parseDOM: [{ tag: 'hr' }],
    toDOM() {
      return hrDOM
    },
  },

  heading: {
    attrs: {
      level: { default: 1 },
      alignment: { default: null },
      indent: { default: null },
      fontSize: { default: null },
      fontFamily: { default: null },
      lineHeight: { default: null },
      fontColor: { default: null },
      fontBackgroundColor: { default: null },
    },
    content: 'inline*',
    group: 'block',
    defining: true,
    parseDOM: [
      {
        tag: 'h1',
        getAttrs: attributesFromDOM({ level: 1 }, headingAttributes),
      },
      {
        tag: 'h2',
        getAttrs: attributesFromDOM({ level: 2 }, headingAttributes),
      },
      {
        tag: 'h3',
        getAttrs: attributesFromDOM({ level: 3 }, headingAttributes),
      },
      {
        tag: 'h4',
        getAttrs: attributesFromDOM({ level: 4 }, headingAttributes),
      },
      {
        tag: 'h5',
        getAttrs: attributesFromDOM({ level: 5 }, headingAttributes),
      },
      {
        tag: 'h6',
        getAttrs: attributesFromDOM({ level: 6 }, headingAttributes),
      },
    ],
    toDOM(node) {
      return ['h' + node.attrs.level, stylesForNode(node), 0]
    },
  },

  text: {
    group: 'inline',
  },

  image: {
    inline: true,
    attrs: {
      src: {},
      alignment: { default: null },
      alt: { default: null },
      title: { default: null },
      width: { default: null },
      height: { default: null },
      assetId: { default: null },
      embeddedVideo: { default: null },
      audioAssetId: { default: null },
      videoAssetId: { default: null },
      autoplay: { default: null },
    },
    group: 'inline',
    draggable: true,
    parseDOM: [
      {
        tag: 'img[src]',
        getAttrs(dom) {
          const floatValue = dom.style.float
          let alignmentValue

          if (floatValue === 'left' || floatValue === 'right') {
            alignmentValue = floatValue
          } else if (
            dom.style.display === 'block' &&
            dom.style.marginLeft === 'auto' &&
            dom.style.marginRight === 'auto'
          ) {
            alignmentValue = 'center'
          }

          return {
            src: dom.getAttribute('src'),
            alt: dom.getAttribute('alt'),
            width: dom.getAttribute('width'),
            height: dom.getAttribute('height'),
            alignment: alignmentValue,
            assetId: dom.getAttribute('data-asset-id'),
            embeddedVideo: dom.getAttribute('data-embedded-video'),
            audioAssetId: dom.getAttribute('data-audio-asset-id'),
            videoAssetId: dom.getAttribute('data-video-asset-id'),
            autoplay: dom.getAttribute('data-autoplay'),
          }
        },
      },
    ],
    toDOM(node) {
      let {
        src,
        alt,
        alignment,
        title,
        width,
        height,
        audioAssetId,
        videoAssetId,
        assetId,
        embeddedVideo,
        autoplay,
      } = node.attrs

      let style
      if (alignment == 'left' || alignment == 'right') {
        style = `float: ${alignment}`
      } else if (alignment == 'center') {
        style = 'display: block; margin-left: auto; margin-right: auto;'
      }

      return [
        'img',
        {
          src,
          alt,
          title,
          width,
          height,
          style,
          align: alignment === 'center' ? 'middle' : alignment,
          'data-audio-asset-id': audioAssetId,
          'data-video-asset-id': videoAssetId,
          'data-asset-id': assetId,
          'data-embedded-video': embeddedVideo,
          'data-autoplay': autoplay,
        },
      ]
    },
  },

  hard_break: {
    inline: true,
    group: 'inline',
    selectable: false,
    parseDOM: [{ tag: 'br' }],
    toDOM() {
      return brDOM
    },
  },

  anchor: {
    inline: true,
    group: 'inline',
    selectable: false,
    attrs: {
      id: {},
    },
    parseDOM: [
      {
        tag: 'a',
        getAttrs(dom) {
          const id = dom.getAttribute('id')
          const href = dom.getAttribute('href')

          if (id && !href) {
            return { id: dom.getAttribute('id') }
          }

          return false
        },
      },
    ],
    toDOM(node) {
      return ['a', { id: node.attrs.id }]
    },
  },

  preformatted: {
    content: 'inline*',
    group: 'block',
    defining: true,
    parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
    toDOM() {
      return ['pre', 0]
    },
  },

  ordered_list: {
    ...orderedList,
    attrs: { order: { default: 1 }, class: { default: 'prosemirror-list' } },
    content: 'list_item+',
    group: 'block',
    parseDOM: [
      {
        tag: 'ol',
        getAttrs(dom) {
          return {
            order: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1,
            class: attachClass(
              dom.getAttribute('class') || null,
              'prosemirror-list'
            ),
          }
        },
      },
    ],
    toDOM(node) {
      return node.attrs.order == 1
        ? ['ol', { class: node.attrs.class }, 0]
        : ['ol', { start: node.attrs.order, class: node.attrs.class }, 0]
    },
  },

  bullet_list: {
    ...bulletList,
    attrs: { class: { default: 'prosemirror-list' } },
    content: 'list_item+',
    group: 'block',
    parseDOM: [
      {
        tag: 'ul',
        getAttrs(dom) {
          return {
            class: attachClass(
              dom.getAttribute('class') || null,
              'prosemirror-list'
            ),
          }
        },
      },
    ],
    toDOM(node) {
      return ['ul', { class: node.attrs.class }, 0]
    },
  },

  list_item: {
    content: 'paragraph block*',
    ...listItem,
  },
}

const emDOM = ['em', 0],
  strongDOM = ['strong', 0],
  codeDOM = ['code', 0]

export const marks = {
  link: {
    attrs: {
      class: { default: null },
      target: { default: null },
      href: { default: null },
      title: { default: null },
      fontColor: { default: null },
      fontBackgroundColor: { default: null },
      internalUri: { default: null },
      magicLogin: { default: null },
      rel: { default: null },
    },
    parseDOM: [
      {
        tag: 'a[href]',
        getAttrs(dom) {
          return {
            href: dom.getAttribute('href'),
            title: dom.getAttribute('title'),
            target: dom.getAttribute('target'),
            class: dom.getAttribute('class'),
            fontColor: dom.style.color || null,
            fontBackgroundColor: dom.style.backgroundColor || null,
            internalUri: dom.getAttribute('data-internal-uri'),
            magicLogin: dom.getAttribute('data-magic-login'),
            rel: dom.getAttribute('rel'),
          }
        },
      },
    ],
    toDOM(node) {
      let {
        href,
        title,
        target,
        fontColor,
        fontBackgroundColor,
        internalUri,
        magicLogin,
        rel,
      } = node.attrs

      let style = []

      let linkAttributes = {
        href,
        title,
        target,
        rel,
        class: node.attrs.class,
      }

      if (fontColor) {
        style.push(`color:${fontColor}`)
      }

      if (fontBackgroundColor) {
        style.push(`background-color:${fontBackgroundColor}`)
        style.push(`border-color: ${fontBackgroundColor}`)
      }

      if (style) {
        linkAttributes.style = style.join(';')
      }

      if (internalUri) linkAttributes['data-internal-uri'] = internalUri
      if (magicLogin) linkAttributes['data-magic-login'] = magicLogin

      return ['a', linkAttributes, 0]
    },
  },

  em: {
    parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
    toDOM() {
      return emDOM
    },
  },

  strong: {
    parseDOM: [
      { tag: 'strong' },
      {
        tag: 'b',
        getAttrs: (node) => node.style.fontWeight != 'normal' && null,
      },
      {
        style: 'font-weight',
        getAttrs: (value) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
      },
    ],
    toDOM() {
      return strongDOM
    },
  },

  code: {
    parseDOM: [{ tag: 'code' }],
    toDOM() {
      return codeDOM
    },
  },

  lineHeight: {
    attrs: {
      lineHeight: {},
    },
    parseDOM: [
      {
        tag: 'span',
        getAttrs(node) {
          if (node.style.lineHeight) {
            return { lineHeight: node.style.lineHeight }
          }
          return false
        },
      },
    ],
    toDOM(mark) {
      return ['span', stylesForNode(mark), 0]
    },
  },

  strikethrough: {
    attrs: {
      strikethrough: true,
    },
    parseDOM: [
      {
        style: 'text-decoration',
        getAttrs(value) {
          return (
            (String(value).match(/line-through/) && { strikethrough: true }) ||
            null
          )
        },
      },
    ],
    toDOM(mark) {
      return ['span', stylesForNode(mark), 0]
    },
  },

  underline: {
    attrs: {
      underlined: true,
    },
    parseDOM: [
      {
        style: 'text-decoration',
        getAttrs(value) {
          return (
            (String(value).match(/underline/) && { underlined: true }) || null
          )
        },
      },
    ],
    toDOM(mark) {
      return ['span', stylesForNode(mark), 0]
    },
  },

  fontColor: {
    attrs: {
      fontColor: {},
    },
    parseDOM: [
      {
        tag: 'span',
        getAttrs(node) {
          if (node.style.color) {
            return { fontColor: node.style.color }
          }
          return false
        },
      },
    ],
    toDOM(node) {
      return ['span', stylesForNode(node), 0]
    },
  },

  fontFamily: {
    attrs: {
      fontFamily: {},
    },
    parseDOM: [
      {
        tag: 'span',
        getAttrs(node) {
          if (node.style.fontFamily) {
            return { fontFamily: node.style.fontFamily }
          }
          return false
        },
      },
    ],
    toDOM(node) {
      return ['span', stylesForNode(node), 0]
    },
  },

  fontSize: {
    attrs: {
      fontSize: {},
    },
    parseDOM: [
      {
        tag: 'span',
        getAttrs(node) {
          if (node.style.fontSize) {
            return { fontSize: node.style.fontSize }
          }
          return false
        },
      },
    ],
    toDOM(node) {
      return ['span', stylesForNode(node), 0]
    },
  },

  fontBackgroundColor: {
    attrs: {
      fontBackgroundColor: {},
    },
    parseDOM: [
      {
        tag: 'span',
        getAttrs(node) {
          if (node.style.backgroundColor) {
            return { fontBackgroundColor: node.style.backgroundColor }
          }
          return false
        },
      },
    ],
    toDOM(node) {
      return ['span', stylesForNode(node), 0]
    },
  },
}

let s = new Schema({ nodes })
let withTableNodes = s.spec.nodes.append(getTableNodes())

export const SimpleroSchema = new Schema({
  nodes: withTableNodes,
  marks: marks,
})
