import crel from 'crelt'
import autocomplete, {
  ActionKind,
  closeAutocomplete,
} from 'prosemirror-autocomplete'
import { find, addClass, removeClass } from '../../../../common/helpers/dom'
import { preparePersonalizationText } from '../menubar'

let interpolationDropdownDomId = 1024
const disallowedPersonalizations = ['countdown_timer']

function containsText(text, substring) {
  return text.toLowerCase().indexOf(substring.toLowerCase()) >= 0
}

export function interpolationAutocompletePlugin(options) {
  // Don't enable this plugin when there's no personalizations
  if (!options.personalizations || options.personalizations.length === 0) {
    return null
  }

  const picker = {
    view: null,
    open: false,
    current: 0,
    range: null,
  }

  const domId = `pm-ac-${interpolationDropdownDomId++}`
  const personalizations = options.personalizations.filter(
    (v) => !disallowedPersonalizations.includes(v[0])
  )

  let numSuggestions = personalizations.length
  const suggestion = crel(
    'div',
    { id: `${domId}-dropdown`, class: 'ProseMirror-interpolation-dropdown' },
    []
  )

  const makeSuggestions = (filter) => {
    const items = personalizations
      .filter((v) => {
        return (
          !filter || containsText(v[0], filter) || containsText(v[1], filter)
        )
      })
      .map((v, index) => {
        const item = crel(
          'li',
          {
            'data-index': index,
            'data-key': v[0],
            class: `suggestion-item suggestion-item-${index}`,
          },
          [
            v[1],
            crel(
              'span',
              { class: 'interpolation-substitution-key' },
              `(${v[0]})`
            ),
          ]
        )

        item.addEventListener('mouseenter', () => {
          const selectedLi = find('li.selected', suggestion)
          selectedLi && removeClass(selectedLi, 'selected')
          picker.current = index

          addClass(item, 'selected')
        })

        item.addEventListener('mouseleave', () => {
          removeClass(item, 'selected')
        })

        item.addEventListener('click', (e) => {
          if (!picker.open) return
          if (!picker.view) return

          preparePersonalizationText(v[0], v[1], (v) => {
            const tr = picker.view.state.tr
              .deleteRange(picker.range.from, picker.range.to)
              .insertText(v)

            picker.view.dispatch(tr)
            picker.view.focus()

            picker.open = false
            picker.current = 0
            positionSuggestion()
          })
        })
        return item
      })

    return crel('ul', {}, items)
  }

  const insertSuggestions = (filter) => {
    const items = makeSuggestions(filter)
    suggestion.replaceChildren(items)
    numSuggestions = items.children.length
    picker.current = 0
  }

  const personalizationKeyAndLabel = (li) => {
    const key = li.getAttribute('data-key')
    const label = li.innerHTML
    return { key, label }
  }

  document.addEventListener('keydown', (e) => {
    if (e.keyCode == 27 && picker.view) {
      picker.current = 0
      picker.open = false
      positionSuggestion()
    }
  })

  function positionSuggestion() {
    suggestion.style.display = picker.open ? 'block' : 'none'
    const el = document.getElementById(`${domId}-text`)
    const rect = el && el.getBoundingClientRect()

    if (!rect) return
    suggestion.style.top = `${rect.top + window.scrollY + rect.height}px`
    suggestion.style.left = `${rect.left + window.scrollX}px`

    Array.from(find('ul', suggestion).children).forEach((item, i) => {
      item.classList[i === picker.current ? 'add' : 'remove']('selected')
    })
  }

  function reducer(action) {
    switch (action.kind) {
      case ActionKind.open:
        picker.view = action.view
        picker.current = 0
        picker.open = true
        picker.range = action.range
        insertSuggestions()
        positionSuggestion()
        return true

      case ActionKind.up:
        picker.current -= 1
        picker.current += numSuggestions
        picker.current %= numSuggestions
        positionSuggestion()
        return true
      case ActionKind.down:
        picker.current += 1
        picker.current %= numSuggestions
        positionSuggestion()
        return true
      case ActionKind.filter:
        picker.range = action.range

        if (action.filter && action.filter.endsWith('}')) {
          picker.open = false
          closeAutocomplete(action.view)
        } else {
          insertSuggestions(action.filter)
        }

        positionSuggestion()
        return true

      case ActionKind.enter: {
        const li = Array.from(find('ul', suggestion).children)[picker.current]
        const { key, label } = personalizationKeyAndLabel(li)

        preparePersonalizationText(key, label, (v) => {
          const tr = action.view.state.tr
            .deleteRange(action.range.from, action.range.to)
            .insertText(v)
          action.view.dispatch(tr)
        })

        return true
      }
      case ActionKind.close:
        picker.open = false
        positionSuggestion()
        return true
      default:
        return false
    }
  }

  const autocompleteOptions = {
    reducer,
    triggers: [
      {
        name: 'interpolation',
        trigger: '{{',
        decorationAttrs: {
          id: `${domId}-text`,
          class: 'ProseMirror-interpolation-text',
        },
      },
    ],
  }

  document.body.appendChild(suggestion)

  return autocomplete(autocompleteOptions)
}
