import crel from 'crelt'
import { MenuItem } from 'prosemirror-menu'
import { icon, documentFromHTMLString } from '../utils'
import { toHTML } from '../index'

export function wysiwygContentTemplatesMenuItem(schema, options) {
  return new MenuItem({
    icon: icon('memo-pad').dom,
    title: 'Macros',
    class: 'prosemirror-wysiwyg-content-templates',

    render(view) {
      const reactRoot = crel('div', {})
      const container = crel('div', {}, [reactRoot])
      container.onmousedown = (e) => {
        e.stopImmediatePropagation()
      }

      import('~/admin/react/components/wysiwyg_content_template_selector').then(
        ({ renderWysiwygContentTemplateSelector }) => {
          renderWysiwygContentTemplateSelector(reactRoot, {
            purpose: options.wysiwygContentTemplatesPurpose,
            getBodyForNewTemplate: () => toHTML(view),
            onTemplateSelect: (html) =>
              view.dispatch(
                view.state.tr.replaceSelectionWith(documentFromHTMLString(html))
              ),
          })
        }
      )
      return container
    },
    run() {
      /*noop*/
    },
  })
}
