import crel from 'crelt'

import { EditorState, Plugin, Selection, PluginKey } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { exampleSetup } from 'prosemirror-example-setup'
import { DOMSerializer } from 'prosemirror-model'
import { keydownHandler, keymap } from 'prosemirror-keymap'
import { chainCommands } from 'prosemirror-commands'
import { Decoration, DecorationSet } from 'prosemirror-view'

import { SimpleroSchema } from './schema'
import {
  buildMenu,
  showSimpleroLinkDialog,
  indent,
  outdent,
  textAlign,
} from './menubar'
import {
  titleCaps,
  hyphenize,
  isRangeSelection,
  isValidUrl,
  insertLink,
  replaceMark,
  getComputedStyle,
  ProsemirrorElements,
  isTextSelection,
  embedVideoDialog,
  youtubeOrVimeoUrl,
  getParentNodeOfType,
  getParentNodeAndPositionOfType,
  documentFromHTMLString,
  splitBlockWithAttributes,
  hasNonEmptyHTMLContent,
  fontAwesomeIcon,
} from './utils'

import { withTracking } from './utils/analytics'

import {
  indentList,
  dedentList,
  handleListNodesWithOnlyBreaks,
  joinUpListNodesCorrectly,
} from './utils/list'

import { columnResizing } from './utils/table_column_resizing'
import { AnchorView, ImageView, TableView } from './node_views'
import { find } from '../../../common/helpers/dom'
import { interpolationPlugin } from './plugins/interpolation'
import { editorOptionsPlugin } from './plugins/editor_options'
import { interpolationAutocompletePlugin } from './plugins/interpolation_autocomplete'
import { textHighlightContextMenu } from './plugins/text_highlight_context_menu'
import { linkInfoDisplayBoxPlugin } from './plugins/link_info_display_box'

import { linkCursorPlugin } from './plugins/link_cursor_plugin'

import { postFn } from '../../../common/helpers/api'

import { tableEditing, fixTables } from 'prosemirror-tables'
import {
  MENU_TEXT_COLOR,
  SWITCH_TO_PROSEMIRROR_LABEL,
  SWITCH_TO_TINYMCE_LABEL,
} from './constants'

import 'prosemirror-view/style/prosemirror.css'
import 'prosemirror-example-setup/style/style.css'
import 'prosemirror-menu/style/menu.css'
import 'prosemirror-tables/style/tables.css'
import 'prosemirror-gapcursor/style/gapcursor.css'
import './wysiwyg.scss'
import './menu.scss'
import './fake-cursor.scss'

import $ from 'jquery'

// Keep a global store of prosemirror editors
window.proseMirror = {
  editors: {},
  events: {},

  editorKey(id) {
    const modifiedKey = String(id).replace('prosemirror_editor_', '')
    return this.editors[id]
      ? id
      : this.editors[modifiedKey]
      ? modifiedKey
      : null
  },
  get(id) {
    return this.editors[this.editorKey(id)]
      ? this.editors[this.editorKey(id)]
      : null
  },
  remove(id) {
    if (this.editorKey(id)) {
      const key = this.editorKey(id)
      this.editors[key] && this.editors[key].remove()
      delete this.editors[key]
    }
  },
  trigger: function (event, ...args) {
    if (this.events[event]) {
      this.events[event].forEach((cb) => cb(...args))
    }
  },
  isCallbackAttached: function (event, cb) {
    return this.events[event].indexOf(cb) > -1
  },
  on: function (event, cb) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    if (typeof cb === 'function' && !this.isCallbackAttached(event, cb)) {
      this.events[event].push(cb)
    }
  },
  off: function (event, cb = null) {
    if (this.events[event]) {
      if (cb && this.isCallbackAttached(event, cb)) {
        this.events[event].splice(this.events[event].indexOf(cb), 1)
      } else if (!cb) {
        this.events[event] = []
      }
    }
  },

  init: proseMirror,

  addEditorSwitch,
  reinitializeObservers,
}

class ProseMirrorProxy {
  constructor(stateFn) {
    this.events = {}
    this.stateFn = stateFn
  }

  setView(view) {
    this.view = view
  }

  setElements(prosemirrorElements) {
    this.prosemirrorElements = prosemirrorElements
  }

  remove() {
    removeProseMirrorInstance(this.prosemirrorElements.input)
  }

  getContent(opts = {}) {
    if (opts['format'] == 'text') {
      return this.view.state.doc.textContent
    } else {
      return this.getValue()
    }
  }

  getValue() {
    return toHTML(this.view)
  }

  setValue(value) {
    this.view.updateState(this.stateFn.call(null, value))
    this.prosemirrorElements.input.val(value)
  }

  setContent(value) {
    this.setValue(value)
  }

  // Inserts content in the beginning for TinyMCE, at the end for ProseMirror
  insertContent(value) {
    this.setValue(this.getValue() + value)
  }

  on(event, callback) {
    if (typeof callback !== 'function') {
      return
    }

    if (!this.events[event]) {
      this.events[event] = []
    }

    this.events[event].push(callback)
  }

  trigger(event, ...args) {
    if (this.events[event]) {
      this.events[event].forEach((cb) => {
        cb.call(this.view, ...args)
      })
    }
  }

  // Focuses the editor placing the cursor at the end
  focus = () => {
    this.view.focus()

    // Place cursor at the end
    const selection = Selection.atEnd(this.view.docView.node)
    const tr = this.view.state.tr.setSelection(selection)
    const state = this.view.state.apply(tr)
    this.view.updateState(state)
  }
}

const key = new PluginKey('placeholder')
// When an image is being uploaded, use a placeholder until the image is fully uploaded
// https://prosemirror.net/examples/upload/
const placeholderPlugin = new Plugin({
  key,
  state: {
    init() {
      return DecorationSet.empty
    },
    apply(tr, set) {
      // Adjust decoration positions to changes made by the transaction
      set = set.map(tr.mapping, tr.doc)
      // See if the transaction adds or removes any placeholders
      let action = tr.getMeta(key)
      if (action && action.add) {
        let widget = document.createElement('img')
        widget.src = URL.createObjectURL(action.add.id.clipboardImage)
        let deco = Decoration.widget(action.add.pos, widget, {
          id: action.add.id,
        })
        set = set.add(tr.doc, [deco])
      } else if (action && action.remove) {
        set = set.remove(
          set.find(null, null, (spec) => spec.id == action.remove.id)
        )
      }
      return set
    },
  },
  props: {
    decorations(state) {
      return key.getState(state)
    },
  },
})

function getPlugins(options, editorType) {
  const getEmojiPicker = () => {
    // Click on the menu item
    const editorInstance = window.proseMirror.get(options.editorId)
    if (editorInstance) {
      const prosemirrorElements = editorInstance.prosemirrorElements
      const emojiPicker = prosemirrorElements.emojiPicker
      return { prosemirrorElements, emojiPicker }
    }
    return {}
  }

  const customKeyMap = keydownHandler({
    'Cmd-k': withTracking(
      'Shortcut',
      { shortcut: 'cmd-k' },
      (state, dispatch) => {
        showSimpleroLinkDialog(state, dispatch)
      }
    ),
    Tab: indentList,
    'Shift-Tab': dedentList,
    Enter: chainCommands(
      splitBlockWithAttributes,
      handleListNodesWithOnlyBreaks
    ),
    Backspace: joinUpListNodesCorrectly,
    'Cmd-]': withTracking(
      'Shortcut',
      { shortcut: 'Cmd-]' },
      (state, dispatch) => {
        indent(state, dispatch)
        return true
      }
    ),
    'Cmd-[': withTracking(
      'Shortcut',
      { shortcut: 'Cmd-[' },
      (state, dispatch) => {
        outdent(state, dispatch)
        return true
      }
    ),
    'Cmd-Shift-y': withTracking(
      'Shortcut',
      { shortcut: 'Cmd-shift-y' },
      (state, dispatch, view) => {
        textAlign('left', view)
        return true
      }
    ),
    'Cmd-Shift-r': withTracking(
      'Shortcut',
      { shorcut: 'Cmd-shift-r' },
      (state, dispatch, view) => {
        textAlign('right', view)
        return true
      }
    ),
    'Cmd-Shift-e': withTracking(
      'Shortcut',
      { shortcut: 'Cmd-shift-e' },
      (state, dispatch, view) => {
        textAlign('center', view)
        return true
      }
    ),
    'Cmd-Shift-j': withTracking(
      'Shortcut',
      { shortcut: 'Cmd-shift-j' },
      (state, dispatch, view) => {
        textAlign('justify', view)
        return true
      }
    ),
    'Cmd-e': withTracking(
      'Shortcut',
      { shortcut: 'Cmd-e' },
      (_state, _dispatch, view) => {
        // Get the position
        const { state } = view
        const { from, to } = state.selection
        const start = view.coordsAtPos(from)

        // Range selection
        if (from != to) {
          return
        }

        const { prosemirrorElements, emojiPicker } = getEmojiPicker()

        if (emojiPicker) {
          const offsetParentPosition =
            emojiPicker.offsetParent.getBoundingClientRect()

          emojiPicker.style.position = 'absolute'

          emojiPicker.style.left =
            start.left - offsetParentPosition.left + 3 + 'px'

          emojiPicker.style.top =
            start.top - offsetParentPosition.top + 16 + 'px'

          prosemirrorElements.emojiPickerMenuItem.click()
        }
      }
    ),
  })

  const escapeKeyHandler = keymap({
    Esc: () => {
      const { prosemirrorElements, emojiPicker } = getEmojiPicker()
      if (emojiPicker) {
        prosemirrorElements.resetEmojiPickerPosition()
        prosemirrorElements.hideEmojiPicker()
      }
      return true
    },
  })

  const preventEventKeymapEventsFromBubbling = (view, event) => {
    if (customKeyMap(view, event)) {
      event.stopPropagation()
      return true
    }

    return false
  }

  // Order is important. At least, plugins exported by exampleSetup need to be
  // added after interpolationAutocompletePlugins. Events are handed to plugins in the
  // order they were added.
  let allPlugins = [
    escapeKeyHandler,
    editorOptionsPlugin,
    columnResizing({ View: TableView }),
    tableEditing(),
    interpolationPlugin,
    linkCursorPlugin({ markType: SimpleroSchema.marks.link }),
  ]

  const interpolationAutocomplete = interpolationAutocompletePlugin(options)
  if (interpolationAutocomplete) {
    allPlugins = allPlugins.concat(interpolationAutocomplete)
  }

  // This needs come after interpolation autocomplete. Because both handle enter key.
  allPlugins.push(
    new Plugin({
      props: { handleKeyDown: preventEventKeymapEventsFromBubbling },
    })
  )

  allPlugins.push(placeholderPlugin)

  const [contextMenuItems, menuContent] = buildMenu(
    SimpleroSchema,
    options,
    editorType
  )

  const exampleSetupOptions = {
    schema: SimpleroSchema,
    floatingMenu: !options.inline,
    menuContent,
  }

  allPlugins = allPlugins.concat(exampleSetup(exampleSetupOptions))

  allPlugins.push(linkInfoDisplayBoxPlugin())

  if (contextMenuItems && contextMenuItems.length) {
    allPlugins = allPlugins.concat([textHighlightContextMenu(contextMenuItems)])
  }

  return allPlugins
}

function idForProseMirrorEditorView(id) {
  return `prosemirror_editor_${id}`
}

function handleEmptyParagraphs(content) {
  return content.replaceAll('<p></p>', '<p><br></p>')
}

export function toHTML(view) {
  const el = document.createElement('div')

  el.appendChild(
    DOMSerializer.fromSchema(SimpleroSchema).serializeFragment(view.state.doc)
  )

  return handleEmptyParagraphs(el.innerHTML)
}

function stateMaker(input, editorOptions) {
  const editorType = input.hasClass('tinymce-admin') ? 'admin' : 'regular'

  const options = {
    editorId: editorOptions.editorId,
    fonts:
      input.data('fonts') ||
      $(document.body).data('fonts') ||
      $(window.top.document.body).data('fonts') ||
      null,
    inline: editorOptions.inline,
    admin: editorOptions.admin,
    embeddedVideo: input.data('tinymce-embedded-video') || false,
    personalizations:
      input.data('personalizations') || editorOptions.personalizations,
    contextObjectType: input.data('context-object-type') || null,
    internalUriResourcesToExclude: input.data(
      'internal-uri-resources-to-exclude'
    )
      ? input.data('internal-uri-resources-to-exclude').split(' ')
      : [],
    internalUriCurrentSiteGlobalId:
      input.data('internal-uri-current-site-global-id') ||
      editorOptions.internalUriCurrentSiteGlobalId,
    enableWysiwygContentTemplates:
      input.data('enable-wysiwyg-content-templates') || false,
    wysiwygContentTemplatesPurpose:
      input.data('wysiwyg-content-templates-purpose') || undefined,
  }

  return (html) => {
    return EditorState.create({
      doc: documentFromHTMLString(html || input.val()),
      plugins: getPlugins(options, editorType),
      editorOptions: options,
    })
  }
}

function getLocalDraftManager(input) {
  return input.data('simplero-manager-local-draft-manager')
}

function clipboardImageAsFile(ev) {
  if (!ev.clipboardData) {
    return false
  }

  if (ev.clipboardData.items.length === 0) {
    return false
  }

  const image = Array.from(ev.clipboardData.items).find((item) =>
    item.type.startsWith('image/')
  )

  return image && image.getAsFile()
}

function findPlaceholder(state, id) {
  const decos = placeholderPlugin.getState(state)
  const found = decos.find(null, null, (spec) => spec.id == id)
  return found.length ? found[0].from : null
}

function uploadImage(clipboardImage, view) {
  let id = { clipboardImage }

  // Replace the selection with a placeholder
  let tr = view.state.tr
  if (!tr.selection.empty) tr.deleteSelection()
  tr.setMeta(placeholderPlugin, { add: { id, pos: tr.selection.from } })
  view.dispatch(tr)

  const formData = new FormData()
  formData.append('file', clipboardImage, clipboardImage.name)

  postFn('/public_assets', formData, { withCredentials: false })()
    .then((response) => {
      if (response && response.url) {
        let pos = findPlaceholder(view.state, id)

        if (pos == null) return

        view.dispatch(
          view.state.tr
            .replaceWith(
              pos,
              pos,
              view.state.schema.nodes.image.create({ src: response.url })
            )
            .setMeta(placeholderPlugin, { remove: { id } })
        )
      } else {
        view.dispatch(tr.setMeta(placeholderPlugin, { remove: { id } }))
      }
    })
    .catch(() => {
      view.dispatch(tr.setMeta(placeholderPlugin, { remove: { id } }))
    })
}

function proseMirror(input, options = {}) {
  const id = options.id || input.attr('id')
  const editorId = idForProseMirrorEditorView(id)
  const cssTagId = 'wysiwyg_css_tag'
  const defaultStyles = {}
  const isInlineEditor = !!options.inline
  const noTextArea = !!options.inline
  const content = options.content
  const readonly = !!input.attr('readonly')

  if ($(`#${cssTagId}`).length === 0) {
    let cssCompiledAt = $('body').data('css-compiled-at')
    $('head').append(
      `<link rel="stylesheet" type="text/css" href="/wysiwyg.css?t=${cssCompiledAt}"  id="${cssTagId}" />`
    )
  }

  // insert editor view
  const editor = $(
    `<div class='prosemirror-editor wysiwyg-content ${
      isInlineEditor ? 'prosemirror-inline-editor' : ''
    }' id="${editorId}"></div>`
  ).insertBefore(input)

  if (!isInlineEditor) {
    const minHeight = `${
      input.data('tinymce-autoresize-min-height') || input.outerHeight() || 120
    }px`
    let maxHeight = input.data('tinymce-autoresize-max-height') || 400
    if (maxHeight !== 'none') {
      maxHeight = `${maxHeight}px`
    }

    editor[0].style.setProperty('--editor-content-min-height', minHeight)
    editor[0].style.setProperty('--editor-content-max-height', maxHeight)
  }

  const stateFn = stateMaker(input, { ...options, editorId })
  let state = stateFn(noTextArea && content)

  const fix = fixTables(state)
  if (fix) {
    state = state.apply(fix.setMeta('addToHistory', false))
  }

  let prosemirrorElements
  const editorElement = document.getElementById(editorId)
  const proseMirrorProxy = new ProseMirrorProxy(stateFn)

  const setFontSizeValue = (value, _view) => {
    const input = find(
      '.ProseMirror-font-size-container input',
      prosemirrorElements.menubar
    )

    if (!input) return

    value = parseInt(String(value).replace('px', ''))
    if (!value) {
      input.value = ''
    } else {
      input.value = value
    }
  }

  // For computed fonts, find the first font in our list that matches the earliest component
  // Ex: value = Arial, Roboto;  fonts = [ "Roboto, Ariel", "Ariel, Helvetica"];
  // Result: "Ariel, Helvetica"
  // Cache the result. This will run a lot of times.
  const fontMatchedCache = {}
  const findFontFromComputedStyle = (fonts, value) => {
    if (fontMatchedCache[value]) {
      return fontMatchedCache[value] === -1 ? null : fontMatchedCache[value]
    }

    // Just use the first font from the value
    const componentFonts = value
      .replace(/["']/g, '')
      .split(',')
      .map((v) => v.trim())
      .slice(0, 1)
    for (let i = 0; i < componentFonts.length; i++) {
      let lowestIndex = Number.MAX_SAFE_INTEGER
      let selectedFont = null
      for (let j = 0; j < fonts.length; j++) {
        const currentIndex = fonts[j].extraData.cssString.indexOf(
          componentFonts[i]
        )
        if (currentIndex >= 0 && currentIndex < lowestIndex) {
          lowestIndex = currentIndex
          selectedFont = fonts[j]
        }
      }
      if (selectedFont) {
        return (fontMatchedCache[value] = selectedFont)
      }
    }
    return (fontMatchedCache[value] = -1) && null
  }

  const setFontFamilyValue = (value, _view) => {
    const container = find(
      '.ProseMirror-font-select-container',
      prosemirrorElements.menubar
    )
    if (container && container.fonts && container.selectWidget) {
      // Clear selection
      if (!value) {
        container.selectWidget.$set({ selectedValue: null })
      } else {
        let selected =
          Object.values(container.fonts).find(
            (font) => font.extraData.cssString == value
          ) || findFontFromComputedStyle(Object.values(container.fonts), value)
        if (selected) {
          container.selectWidget.$set({ selectedValue: selected })
        } else {
          container.selectWidget.$set({ selectedValue: null })
        }
      }
    }
  }

  const getMarksOfType = (start, end, type, view) => {
    let fontMarks = []
    view.state.doc.nodesBetween(start.pos, end.pos, (node) => {
      const m = node.marks.filter((m) => m.type == type)
      fontMarks = fontMarks.concat(m)
    })
    return fontMarks
  }

  const getDefaultStyle = (view, attributeName) => {
    const cachedValue = defaultStyles[attributeName]
    if (cachedValue) {
      return cachedValue === -1 ? null : cachedValue
    }

    return (defaultStyles[attributeName] =
      getComputedStyle(view.dom, hyphenize(attributeName)) || -1)
  }

  const setColorPreview = (klass) => (value, _view) => {
    const icon = find(`${klass} i`, prosemirrorElements.menubar)
    if (!icon) return
    icon.style.color = value || MENU_TEXT_COLOR
  }

  const showAttributeValue = (view, attributeName, valueSetter) => {
    // For range selection, if there's exactly one mark of font size type. Use it.
    // If there are multiple font sizes, then don't show font size at all.
    if (!view.state.selection.empty) {
      const { $from, $to } = view.state.selection.ranges[0]
      const marks = getMarksOfType(
        $from,
        $to,
        view.state.schema.marks[attributeName],
        view
      )
      valueSetter(
        marks.length === 1 ? marks[0].attrs[attributeName] : null,
        view
      )
      return
    }

    const fs = getParentNodeOfType(
      view.state.selection,
      view.state.schema.marks[attributeName]
    )
    if (fs) {
      valueSetter(fs.attrs[attributeName], view)
      return
    }

    for (const nodeType of ['paragraph', 'heading']) {
      const { node, position } =
        getParentNodeAndPositionOfType(
          view.state.selection,
          view.state.schema.nodes[nodeType],
          false
        ) || {}

      if (node && node.attrs[attributeName]) {
        return valueSetter(node.attrs[attributeName], view)
      } else if (node) {
        const domEl = view.domAtPos(position)
        return valueSetter(
          getComputedStyle(domEl.node, hyphenize(attributeName)) || -1,
          view
        )
      }
    }

    valueSetter(getDefaultStyle(view, attributeName), view)
  }

  const showFontSizeAndFontFamilySelected = (view) => {
    showAttributeValue(view, 'fontSize', setFontSizeValue)
    showAttributeValue(view, 'fontFamily', setFontFamilyValue)
    showAttributeValue(
      view,
      'fontColor',
      setColorPreview('.prosemirror-font-color')
    )
    showAttributeValue(
      view,
      'fontBackgroundColor',
      setColorPreview('.prosemirror-font-background-color')
    )
  }

  const showAlignmentSelected = (view) => {
    const icons = {
      left: fontAwesomeIcon('align-left').dom,
      center: fontAwesomeIcon('align-center').dom,
      right: fontAwesomeIcon('align-right').dom,
      justify: fontAwesomeIcon('align-justify').dom,
    }

    showAttributeValue(view, 'alignment', (value) => {
      let container = find(
        '.ProseMirror-text-alignment',
        prosemirrorElements.menubar
      )

      // If not found in container, try to look for this in the context menubar
      if (!container) {
        container = find(
          '.ProseMirror-text-alignment',
          prosemirrorElements.contextMenu
        )
      }

      const icon = find('i:first-child', container)

      if (!container || !icon) return

      icon.remove()
      container.prepend(!value || !icons[value] ? icons.left : icons[value])
    })
  }

  const setFormatValue = (value) => {
    const container = find(
      '.ProseMirror-format-menu',
      prosemirrorElements.menubar
    )

    if (!container) return

    container.replaceChildren(crel('strong', {}, value))
  }

  const formatName = (node) => {
    if (node.type.name === 'heading') {
      const level = node.attrs.level
      return `Heading ${level}`
    } else {
      return titleCaps(node.type.name)
    }
  }

  const showFormatSelected = (view) => {
    const possibleNodes = ['blockquote', 'paragraph', 'heading', 'preformatted']

    if (isRangeSelection(view.state.selection)) {
      setFormatValue('Paragraph')
      return
    }

    const code = getParentNodeOfType(
      view.state.selection,
      view.state.schema.marks.code
    )
    if (code) {
      setFormatValue('Code')
      return
    }

    for (let i = 0; i < possibleNodes.length; i++) {
      const node = getParentNodeOfType(
        view.state.selection,
        view.state.schema.nodes[possibleNodes[i]],
        false
      )
      if (node) {
        setFormatValue(formatName(node))
        return
      }
    }
  }

  const showMenubar = () => prosemirrorElements.focus()
  const hideMenubar = () => prosemirrorElements.removeFocus()

  const handleWindowClick = (e) => {
    if (
      isInlineEditor &&
      !view.hasFocus() &&
      !prosemirrorElements.editorHasFocus()
    ) {
      hideMenubar()
    }

    prosemirrorElements.resetEmojiPickerPosition()
  }

  window.addEventListener('click', handleWindowClick)

  let valueOnFocus = null

  const view = new EditorView(editorElement, {
    state,
    editable() {
      return !readonly
    },
    dispatchTransaction(tr) {
      const { state } = view.state.applyTransaction(tr)

      view.updateState(state)
      showFontSizeAndFontFamilySelected(view)
      showAlignmentSelected(view)
      showFormatSelected(view)
      prosemirrorElements.resetEmojiPickerPosition()
      prosemirrorElements.hideEmojiPicker()

      let htmlContent = toHTML(view)
      htmlContent = hasNonEmptyHTMLContent(htmlContent) ? htmlContent : ''

      // Content editable div not updating any text area
      if (!noTextArea) {
        input.val(htmlContent)
      }

      proseMirrorProxy.trigger('change', htmlContent)
    },
    handleClick(view, pos, ev) {
      // Is this really needed? Should prosemirror already not do this?
      if (ev.target.tagName.toLowerCase() === 'a') {
        ev.preventDefault()
        ev.stopPropagation()
      }
    },
    handlePaste(view, ev, _slice) {
      const text = ev.clipboardData.getData('text/plain')
      const clipboardImage = clipboardImageAsFile(ev)

      if (clipboardImage) {
        uploadImage(clipboardImage, view)
        return true
      }

      const markType = view.state.schema.marks.link
      if (isValidUrl(text)) {
        if (isTextSelection(view.state.selection)) {
          replaceMark(markType, { href: text })(view.state, view.dispatch)
        } else if (youtubeOrVimeoUrl(text)) {
          embedVideoDialog({ video_url: text }, view, null, true)
        } else {
          insertLink(view, text)
        }
        return true
      }

      return false
    },
    handleDOMEvents: {
      click: (view, e) => {
        const target = e.target
        const closestAnchor = target.closest('a[href]')
        const isAnchor = target.tagName.toUpperCase() === 'A' || closestAnchor

        if (isAnchor && (closestAnchor || target.getAttribute('href'))) {
          e.preventDefault()
          e.stopPropagation()
        }
      },
      focus: (view, e) => {
        isInlineEditor && showMenubar(view)
        valueOnFocus = toHTML(view)
        return true
      },
      focusout: (view, e) => {
        // wait a little
        setTimeout(() => {
          if (isInlineEditor && prosemirrorElements.editorHasFocus()) {
            return
          }
          isInlineEditor && hideMenubar(view)
        }, 200)

        if (input?.trigger && valueOnFocus != toHTML(view)) {
          input.trigger('change')
        }

        return true
      },
    },
    nodeViews: {
      anchor(node, view, getPos) {
        return new AnchorView(node, view, getPos)
      },
      image(node, view, getPos) {
        return new ImageView(node, view, getPos)
      },
    },
  })

  prosemirrorElements = new ProsemirrorElements(view, input, isInlineEditor)
  proseMirrorProxy.setView(view)
  proseMirrorProxy.setElements(prosemirrorElements)

  // Store it globally
  window.proseMirror.editors[id] = proseMirrorProxy
  window.proseMirror.trigger('addeditor', proseMirrorProxy)

  input.data('prosemirrorView', view)
  input.hide()

  // After rendering, calculate menubar position
  if (isInlineEditor) {
    prosemirrorElements.detachMenubar()
  } else {
    prosemirrorElements.addInheritedStyles()
  }

  if (readonly) {
    prosemirrorElements.container.classList.add('prosemirror-editor--readonly')
  }

  if (!options.noDraftManager) {
    const draftManager = getLocalDraftManager(input)
    // Why wrap this in ready callback?
    //
    // 1. Local draft manager is told to remove keys from localStorage after form submission. It does so.
    // 2. When editors are initialized with reference to keys from Local draft manager, it tries to restore the values in them keys
    //
    // There's race condition here. It always worked because between calls to init on TinyMCE and post initialization callbacks, there's a delay.
    // Prosemirror has very little delay, so prosemirror has already restored the drafts before LocalDraftManager has chance to remove it.
    $(() => {
      if (draftManager) {
        draftManager.unsetTinyMCE()
        draftManager.onProseMirrorLoaded(proseMirrorProxy)
      }
    })
  }

  return proseMirrorProxy
}

function removeProseMirrorInstance(input) {
  const draftManager = getLocalDraftManager(input)
  if (draftManager) {
    draftManager.unsetProseMirror()
  }

  const id = input.attr('id')
  delete window.proseMirror.editors[id]

  $(`#${idForProseMirrorEditorView(id)}`).remove()

  input.show()
}

function textArea(link) {
  return link.closest('div').find('textarea')
}

function removeTinyMCE(id) {
  window.tinyMCE.editors[id] && window.tinyMCE.editors[id].remove()
}

function reinitializeObservers(input) {
  const form = $(input).closest('form')
  form.data('observer') &&
    form.data('observer').listenToChangesOnRichTextEditors()
}

function switchToProseMirror(link) {
  localStorage.setItem('editor', 'prosemirror')

  link
    .removeClass('prosemirror')
    .addClass('tinymce')
    .html(SWITCH_TO_TINYMCE_LABEL)

  link.next('.feedback-link').removeClass('hidden')

  const input = textArea(link)
  const id = input.attr('id')

  removeTinyMCE(id)
  proseMirror(input)
  reinitializeObservers(input)
}

function switchToTinyMCE(link) {
  localStorage.setItem('editor', 'tinyMCE')

  link
    .removeClass('tinymce')
    .addClass('prosemirror')
    .html(SWITCH_TO_PROSEMIRROR_LABEL)

  link.next('.feedback-link').addClass('hidden')

  const input = textArea(link)
  removeProseMirrorInstance(input)
  input.changed()
  reinitializeObservers(input)
}

function addEditorSwitch(el, switchToClass, switchToLabel) {
  const $el = $(el)
  const closestDiv = $el.closest('div')

  if (closestDiv.find('.editor-switch').length == 0) {
    $el.closest('div').append(
      `
        <span class='switch-prosemirror'>
          <a class='editor-switch ${switchToClass}' href='#'> ${switchToLabel} </a>
        </span>
      `
    )
  }
}

$(document).on('click', '.editor-switch', (event) => {
  event.preventDefault()

  const $target = $(event.target)
  if ($target.hasClass('prosemirror')) {
    switchToProseMirror($target)
  } else {
    switchToTinyMCE($target)
  }
})

$(document).on('page:before-unload', () => {
  if (window.proseMirror) {
    Object.values(window.proseMirror.editors).forEach((editor) => {
      editor.remove()
    })
  }
})
