// Uses jquery modal to present a very
// simple interface to accept values from a dialog
import $ from 'jquery'
import './dialog.scss'

export class Dialog {
  constructor(id, title, fields, callback, cancel) {
    this.id = id
    this.title = title
    this.fields = fields
    this.callback = callback
    this.cancel = cancel
    this.hasTabs = fields && !Array.isArray(fields)
    this.currentType = this.hasTabs ? Object.keys(fields)[0] : null

    this.prepareElement()
    this.setupColorInput()
  }

  get element() {
    return window.top.$(`#modal-container .modal#${this.id}`)
  }

  getTitle() {
    if (!this.hasTabs) {
      return `<h4 class='modal-title'>${this.title}</h4>`
    }
  }

  prepareElement() {
    this.element.remove()

    window.top.$('#modal-container').append(`
      <div class='modal prosemirror-dialog' id='${this.id}'>
        <div class='modal-dialog'>
          <form>
            <div class='modal-content'>
              <div class='modal-header'>
                <button class='close close-action'>×</button>
                ${this.getTitle() || ''}
                ${this.renderTabs() || ''}
              </div>
              <div class='modal-body'>
                ${this.renderTabbedFields()}
              </div>
              <div class='modal-footer'>
                <button type="submit" class="btn btn-primary apply-action">OK</button>
                <button type="button" class="btn btn-default close-action">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    `)

    const self = this

    this.element.find('.close-action').on('click', () => {
      this.element.modal('hide')
      this.run('cancel')
    })

    this.element.find('ul.nav li a').on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()

      const a = $(e.target)
      const li = a.closest('li')
      const nav = li.closest('ul.nav')

      // Set the current type
      self.currentType = li.data('name')

      nav.find('li').removeClass('active')
      li.addClass('active')

      self.element.find('div.prosemirror-dialog-tab-content').addClass('hide')
      self.element.find(`div.tab-${li.data('name')}`).removeClass('hide')
    })

    this.element
      .find('form')
      .unbind('submit')
      .on('submit', function (e) {
        e.preventDefault()
        const fieldValues = {}

        $(this)
          .find(':input')
          .each(function () {
            fieldValues[this.name] = $(this).val()
          })

        self.done(fieldValues, self.currentType)
        self.hide()
      })
  }

  setupColorInput() {
    this.element.find('.color-input').minicolors({ show: null })
  }

  run(cb, ...args) {
    if (typeof this[cb] === 'function') {
      this[cb](...args)
    }
  }

  done(...args) {
    this.run('callback', ...args)
  }

  renderField(f, options = {}) {
    if (f.type.match(/text|number|color/)) {
      const type = 'text'
      const className =
        'form-control ' +
        (f.type === 'color' ? 'color-input' : '') +
        ' ' +
        (options.class || '')

      return `
        <input type='${type}' name='${f.name}' value='${
        f.value || ''
      }' class='${className}' />
      `
    } else if (f.type == 'label') {
      return `<span class='prosemirror-dialog-field-label'>${f.text}</span>`
    } else if (f.type == 'group' && f.fields) {
      return `<div class='inline-inputs ${f.class || ''}'> ${f.fields
        .map((f) => this.renderField(f, { class: 'inline-input' }))
        .join('')}</div>`
    } else if (f.type === 'select') {
      const options =
        f.options &&
        f.options
          .map(
            ([label, value]) =>
              `<option value='${value}' ${
                f.value == value ? 'selected' : ''
              }> ${label} </option>`
          )
          .join('')
      return `
        <select name='${f.name}' class='form-control'>
          ${options}
        </select>
      `
    }
  }

  renderTabs() {
    if (this.hasTabs) {
      return `
        <ul class='nav table-pills'>
          ${Object.entries(this.fields).map(([name, defn], index) => {
            return `
                <li data-name='${name}' class='${index == 0 ? 'active' : ''}'>
                  <a href='#'> ${defn.heading} </a>
                </li>
              `
          })}
        </ul>
      `
    }
  }

  renderFields(fields) {
    const formFields = fields.map((f) => {
      return `
        <div class='form-group'>
          <label class='inline'> ${f.label} </label>
          ${this.renderField(f)}
        </div>
      `
    })

    return `${formFields.join('')}`
  }

  renderTabbedFields() {
    if (this.hasTabs) {
      return Object.entries(this.fields)
        .map(([name, defn], index) => {
          return `
          <div class='prosemirror-dialog-tab-content tab-${name} ${
            index != 0 ? 'hide' : ''
          }'>
            ${this.renderFields(defn.fields)}
          </div>
        `
        })
        .join('')
    } else {
      return this.renderFields(this.fields)
    }
  }

  show() {
    this.element.modal('show')
  }

  hide() {
    this.element.modal('hide')
  }
}
