import $ from 'jquery'
import crel from 'crelt'

import { Dialog } from '../dialog'
import { inListItem } from './list'
import { DOMParser } from 'prosemirror-model'
import { MenuItem } from 'prosemirror-menu'
import { canSplit } from 'prosemirror-transform'
import { SimpleroSchema } from '../schema'
import { toTitleCaps } from '~/common/helpers/title_caps'

import {
  chainCommands,
  toggleMark,
  newlineInCode,
  createParagraphNear,
  liftEmptyBlock,
} from 'prosemirror-commands'

import { TextSelection, NodeSelection, AllSelection } from 'prosemirror-state'

import {
  find,
  toggleClass,
  hasClass,
  addClass,
  removeClass,
} from '../../../../common/helpers/dom'

import { track } from './analytics'

import { icon, fontAwesomeIcon } from './icons'

const paddingStep = 30

const styleMapping = {
  alignment: (value) => {
    if (!value) return null
    if (!['left', 'right', 'center', 'justify'].includes(value)) return null
    return ['text-align', value]
  },

  strikethrough: (value) => {
    if (!value) return null
    return ['text-decoration', 'line-through']
  },

  indent: (value) => {
    if (value === null) return null
    if (value <= 0) return null
    return ['padding-left', value * paddingStep + 'px']
  },

  underlined: (value) => {
    if (!value) return null
    return ['text-decoration', 'underline']
  },

  fontSize: (value) => {
    if (!value) return null
    return ['font-size', value]
  },

  fontFamily: (value) => {
    if (!value) return null
    return ['font-family', value]
  },

  fontColor: (value) => {
    if (!value) return null
    return ['color', value]
  },

  width: (value) => {
    if (!value) return null
    return ['width', `${value}px`]
  },

  height: (value) => {
    if (!value) return null
    return ['height', `${value}px`]
  },

  fontBackgroundColor: (value) => {
    if (!value) return null
    return ['background-color', value]
  },

  backgroundColor: (value) => {
    if (!value) return null
    return ['background-color', value]
  },

  borderColor: (value) => {
    if (!value) return null
    return ['border-color', value]
  },

  border: (value) => {
    if (!value) return null
    return ['border-width', `${value}px`]
  },

  lineHeight: (value) => {
    if (!value) return null
    if (!String(value).match(/^[\d.]+$/)) return null

    return ['line-height', value]
  },
}

export function stylesForNode(node, options) {
  const { attrs } = node
  const style = {}

  Object.keys(attrs).forEach((key) => {
    const [cssKey, cssValue] =
      (styleMapping[key] && styleMapping[key](attrs[key])) || []
    if (cssValue) style[cssKey] = cssValue

    // Default to solid border, to override any CSS
    if (cssKey == 'border-width' && cssValue) {
      style['border-style'] = (options && options.borderStyle) || 'solid'
    }
  })

  if (Object.keys(style).length > 0) {
    return {
      style: Object.entries(style)
        .map(([k, v]) => `${k}:${v}`)
        .join(';'),
    }
  }

  return {}
}

export function createElementWithContent(content) {
  const element = document.createElement('div')
  element.innerHTML = content
  return element
}

export function documentFromHTMLString(htmlString, partial = false) {
  return DOMParser.fromSchema(SimpleroSchema)[partial ? 'parseSlice' : 'parse'](
    createElementWithContent(htmlString)
  )
}

export function getStylePropertyValue(domNode, property) {
  const style = domNode.getAttribute('style')
  const map = Object.fromEntries(
    String(style)
      .trim()
      .split(';')
      .map((rule) => rule.split(':').map((k) => k.trim()))
  )
  return map[property] || null
}

// Based on Dropdown implementation from prosemirror-menu
let lastMenuEvent = { time: 0, node: null }
function markMenuEvent(e) {
  lastMenuEvent.time = Date.now()
  lastMenuEvent.node = e.target
}

function isMenuEvent(wrapper) {
  return (
    Date.now() - 100 < lastMenuEvent.time &&
    lastMenuEvent.node &&
    wrapper.contains(lastMenuEvent.node)
  )
}

function renderDropdownItems(items, view) {
  let rendered = [],
    updates = []
  for (let i = 0; i < items.length; i++) {
    let { dom, update } = items[i].render(view)
    rendered.push(crel('div', { class: 'ProseMirror-icon-dropdown-item' }, dom))
    updates.push(update)
  }
  return { dom: rendered, update: combineUpdates(updates, rendered) }
}

function combineUpdates(updates, nodes) {
  return (state) => {
    let something = false
    for (let i = 0; i < updates.length; i++) {
      let up = updates[i](state)
      nodes[i].style.display = up ? '' : 'none'
      if (up) something = true
    }
    return something
  }
}

export function placeDropdown(container) {
  const rect = container.getBoundingClientRect()
  const availableWidth = window.innerWidth
  const isOverflowing = rect.x + rect.width >= availableWidth
  const isTooCloseToEdge = 1 - (rect.x + rect.width) / availableWidth < 0.01

  if (isOverflowing || isTooCloseToEdge) {
    container.style.left = `-${rect.width - 30}px`
    addClass(container, 'showing-on-left')
  }
}

export class ToolbarButton {
  constructor(content, options) {
    this.options = options || {}
    this.content = content
  }

  active() {
    return true
  }

  render(view) {
    const div = document.createElement('div')
    const button = crel(
      'button',
      {
        'data-action': 'form--color-picker#togglePopover',
        role: 'presentation',
        hideFocus: '1',
        type: 'button',
        tabIndex: '-1',
        class: this.options.class,
      },
      this.options.icon
    )
    div.innerHTML = document.getElementById(
      'swatch-color-picker-template'
    ).innerHTML
    const el = div
      .querySelector('.color-picker__panel')
      .querySelector('.color-picker-btn-container')
    el.append(button)

    div.addEventListener('color-swatch-picker:reset', () => {
      applyProperty(this.options.key, null, view)
    })

    div.addEventListener('color-swatch-picker:save', (e) => {
      applyProperty(this.options.key, e.detail.color, view, { focus: false })
    })

    div.addEventListener('color-swatch-picker:close', (e) => {
      view.focus()
    })

    function update(state) {
      // let inner = content.update(state)
      // wrap.style.display = inner ? '' : 'none'
      return state
    }

    return { dom: div, update }
  }
}

export class IconDropdown {
  constructor(content, options) {
    this.options = options || {}
    this.content = Array.isArray(content) ? content : [content]
  }

  active() {
    return true
  }

  render(view) {
    let content = renderDropdownItems(this.content, view)
    let expandIcon = fontAwesomeIcon('angle-down').dom

    let label = crel(
      'div',
      {
        class:
          'ProseMirror-icon-dropdown ProseMirror-icon-dropdown ' +
          (this.options.class || ''),
        style: this.options.css,
      },
      [this.options.icon, expandIcon].filter((v) => !!v)
    )
    if (this.options.title) label.setAttribute('title', this.options.title)
    let wrap = crel('div', { class: 'ProseMirror-icon-dropdown-wrap' }, label)
    let open = null,
      listeningOnClose = null
    let close = () => {
      if (open && open.close()) {
        open = null
        window.removeEventListener('mousedown', listeningOnClose)
      }
    }

    const menuOpenHandler = (e) => {
      e.preventDefault()
      markMenuEvent(e)
      if (open) {
        close()
      } else {
        open = this.expand(wrap, content.dom)
        window.addEventListener(
          'mousedown',
          (listeningOnClose = () => {
            if (!isMenuEvent(wrap)) close()
          })
        )
      }
    }

    let options = this.options
    if (options && options.clickHandler) {
      // Send a view with it
      label.addEventListener('mousedown', (e) => {
        e.preventDefault()
        if (e.target != expandIcon) {
          options.clickHandler(view)
        }
      })

      expandIcon.addEventListener('mousedown', menuOpenHandler)
    } else {
      label.addEventListener('mousedown', menuOpenHandler)
    }

    function update(state) {
      let inner = content.update(state)
      wrap.style.display = inner ? '' : 'none'
      return inner
    }

    return { dom: wrap, update }
  }

  expand(dom, items) {
    let menuDOM = crel(
      'div',
      { class: 'ProseMirror-icon-dropdown-menu ' + (this.options.class || '') },
      items
    )

    let done = false
    function close() {
      if (done) return
      done = true
      dom.removeChild(menuDOM)
      return true
    }
    dom.appendChild(menuDOM)

    // Place the menuDOM container
    placeDropdown(menuDOM)

    return { close, node: menuDOM }
  }
}

export function text(text) {
  const el = document.createElement('span')
  el.setAttribute('class', 'ProseMirror-menu-item-text')
  el.innerHTML = text
  return { dom: el }
}

export function getPluginByName(state, pluginKey) {
  return state.plugins.find((p) => p.key == pluginKey.key)
}

export function getPluginState(state, key, defaultValue = null) {
  const plugin = getPluginByName(state, key)
  return plugin ? plugin.getState(state) : defaultValue
}

export function markApplies(doc, ranges, type) {
  for (let i = 0; i < ranges.length; i++) {
    let { $from, $to } = ranges[i]
    let can = $from.depth == 0 ? doc.type.allowsMarkType(type) : false
    doc.nodesBetween($from.pos, $to.pos, (node) => {
      if (can) return false
      can = node.inlineContent && node.type.allowsMarkType(type)
    })
    if (can) return true
  }
  return false
}

export function cmdItem(cmd, options) {
  let passedOptions = {
    label: options.title,
    run: (state, dispatch, view) => {
      track(options.title)
      cmd(state, dispatch, view)
    },
  }
  for (let prop in options) passedOptions[prop] = options[prop]
  if ((!options.enable || options.enable === true) && !options.select)
    passedOptions[options.enable ? 'enable' : 'select'] = (state) => cmd(state)

  return new MenuItem(passedOptions)
}

export function markActive(state, type) {
  let { from, $from, to, empty } = state.selection
  if (empty) return type.isInSet(state.storedMarks || $from.marks())
  else return state.doc.rangeHasMark(from, to, type)
}

// From here: https://discuss.prosemirror.net/t/updating-mark-attributes/776/4
export function markPosition(state, pos, markType) {
  const $pos = state.doc.resolve(pos)

  const { parent, parentOffset } = $pos
  const start = parent.childAfter(parentOffset)
  if (!start.node) return

  const mark = start.node.marks.find((mark) => mark.type === markType)
  if (!mark) return

  let startIndex = $pos.index()
  let from = $pos.start() + start.offset
  let endIndex = startIndex + 1
  let to = from + start.node.nodeSize
  while (startIndex > 0 && mark.isInSet(parent.child(startIndex - 1).marks)) {
    startIndex -= 1
    from -= parent.child(startIndex).nodeSize
  }
  while (
    endIndex < parent.childCount &&
    mark.isInSet(parent.child(endIndex).marks)
  ) {
    to += parent.child(endIndex).nodeSize
    endIndex += 1
  }
  return { from, to, mark }
}

export function replaceMark(markType, attrs) {
  return function (state, dispatch) {
    let { empty, $cursor, ranges } = state.selection
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType))
      return false
    if (dispatch) {
      if ($cursor) {
        if (markType.isInSet(state.storedMarks || $cursor.marks()))
          dispatch(state.tr.removeStoredMark(markType))

        dispatch(state.tr.addStoredMark(markType.create(attrs)))
      } else {
        let has = false,
          tr = state.tr

        for (let i = 0; !has && i < ranges.length; i++) {
          let { $from, $to } = ranges[i]
          has = state.doc.rangeHasMark($from.pos, $to.pos, markType)
        }
        for (let i = 0; i < ranges.length; i++) {
          let { $from, $to } = ranges[i]
          if (has) {
            tr.removeMark($from.pos, $to.pos, markType)
          }

          let from = $from.pos,
            to = $to.pos,
            start = $from.nodeAfter,
            end = $to.nodeBefore
          let spaceStart =
            start && start.isText ? /^\s*/.exec(start.text)[0].length : 0
          let spaceEnd = end && end.isText ? /\s*$/.exec(end.text)[0].length : 0
          if (from + spaceStart < to) {
            from += spaceStart
            to -= spaceEnd
          }
          tr.addMark(from, to, markType.create(attrs))
        }
        dispatch(tr.scrollIntoView())
      }
    }
    return true
  }
}

export function markItem(markType, options, toggle = true) {
  let passedOptions = {
    active(state) {
      return markActive(state, markType)
    },
    enable: true,
  }
  for (let prop in options) passedOptions[prop] = options[prop]

  return cmdItem(
    toggle
      ? toggleMark(markType, passedOptions.attrs)
      : replaceMark(markType, passedOptions.attrs),
    passedOptions
  )
}

export function getParentNodeAndPositionOfType(
  selection,
  nodeType,
  isMark = true
) {
  const selectionStart = selection.$from
  let depth = selectionStart.depth
  let parent

  if (isMark) {
    const marks =
      (selection.node && selection.node.marks) ||
      (selection.$cursor && selection.$cursor.marks())
    if (marks) {
      for (let i = 0; i < marks.length; i++) {
        if (marks[i].type === nodeType) {
          return { node: marks[i], position: null }
        }
      }
    }
  } else {
    do {
      parent = selectionStart.node(depth)
      if (parent) {
        if (parent.type === nodeType) {
          return { node: parent, position: selectionStart.start(depth) }
        }
        depth--
      }
    } while (depth > 0 && parent)
  }

  return null
}

export function getParentNodeOfType(...args) {
  const { node } = getParentNodeAndPositionOfType(...args) || {}
  return node
}

export function hasNonEmptyHTMLContent(content) {
  return !content.match(/^\s*(<p><\/p>)?\s*$/)
}

export function youtubeOrVimeoUrl(url) {
  return String(url).match(
    /^(https?:\/\/)?(www\.)?(m\.)?(youtube\.com\/watch[^\s]+|vimeo\.com\/\d+)$/i
  )
}

export function isValidUrl(urlString) {
  let url
  try {
    url = new URL(urlString)
  } catch (_) {
    return false
  }

  return ['http:', 'https:'].includes(url.protocol)
}

export function insertTextAtSelection(text, view) {
  view.dispatch(
    view.state.tr.replaceSelectionWith(documentFromHTMLString(`<p>${text}</p>`))
  )
}

export function embedVideo(view, videoAttributes) {
  const html = `
    <a href="${videoAttributes.video_url}" target="_blank">
      <img src="${videoAttributes.src}" width="${
    videoAttributes.width || 480
  }" height="${videoAttributes.height || 360}" alt="${
    videoAttributes.alt
  }" data-embedded-video="true" data-asset-id="${videoAttributes.assetId}" />
    </a>
  `
  const newTr = view.state.tr.replaceSelectionWith(documentFromHTMLString(html))

  view.dispatch(newTr)
}

export function embedVideoDialog(
  attrs,
  view,
  imageSrc = null,
  pastedContent = false
) {
  const fields = [
    {
      type: 'text',
      name: 'video_url',
      label: 'Youtube/Vimeo video URL',
      value: attrs && attrs.video_url,
    },
    {
      type: 'text',
      name: 'alt',
      label: 'Preview Description',
      value: attrs && attrs.alt,
    },
    {
      type: 'group',
      label: 'Dimensions',
      fields: [
        {
          type: 'number',
          name: 'width',
          label: 'Width',
          value: attrs && attrs.width,
        },
        { type: 'label', text: 'x' },
        {
          type: 'number',
          name: 'height',
          label: 'Height',
          value: attrs && attrs.height,
        },
      ],
    },
  ]

  const callback = (values) => {
    if (values.video_url !== attrs.video_url || pastedContent) {
      $.post({
        type: 'POST',
        url: '/admin/assets/create_from_video_url',
        data: {
          video_url: values.video_url,
          alt_text: values.alt,
        },
        success: function (data, _status, _xhr) {
          if (!data.asset) {
            alert(
              'An error occurred while preparing preview for this video. Please contact support'
            )
          } else {
            const asset = data.asset

            embedVideo(view, {
              ...values,
              src: asset.src,
              assetId: asset.id,
              embeddedVideo: true,
            })
          }
        },
        error: function (xhr, _error, _message) {
          if (xhr.status === 400) {
            alert(xhr.responseJSON.error)
          }
        },
      })
    } else {
      embedVideo(view, { ...values, src: imageSrc, embeddedVideo: true })
    }
  }

  const cancel = () => {
    if (pastedContent && attrs.video_url) {
      insertTextAtSelection(attrs.video_url, view)
    }
  }

  new Dialog('image', 'Image', fields, callback, cancel).show()
}

export function removeMarkInPosition(state, dispatch, markType) {
  const mPosition = markPosition(state, state.selection.$anchor.pos, markType)
  if (mPosition) {
    dispatch(state.tr.removeMark(mPosition.from, mPosition.to, markType))
  }
}

export function replaceMarkInPosition(
  state,
  dispatch,
  markType,
  newAttrs,
  replaceText = null
) {
  const mPosition = markPosition(state, state.selection.$anchor.pos, markType)

  if (mPosition) {
    const { from, to, mark } = mPosition
    const tr = state.tr
    const newMark = markType.create({ ...mark.attrs, ...newAttrs })

    if (replaceText && newAttrs[replaceText]) {
      tr.replaceWith(
        from,
        to,
        state.schema.text(newAttrs[replaceText], [newMark]),
        false
      )
    } else {
      tr.removeMark(from, to, mark)
      tr.addMark(from, to, newMark)
    }

    dispatch(tr)

    return true
  }

  return false
}

// Only on links allow fontColor and fontBackgroundColor
const allowedPropertyForLink = (key) =>
  ['fontColor', 'fontBackgroundColor'].includes(key)

export function applyProperty(key, value, view, { focus = true } = {}) {
  const { state, dispatch } = view
  const { selection } = state
  const paragraphNode = state.schema.nodes.paragraph
  const headingNode = state.schema.nodes.heading
  const linkMark = state.schema.marks.link
  const markType = state.schema.marks[key]

  if (state.selection.$from.pos != state.selection.$to.pos) {
    replaceMark(markType, { [key]: value })(view.state, view.dispatch)
    if (focus) view.focus()
  } else {
    const paragraph = getParentNodeOfType(state.selection, paragraphNode, false)
    const heading = getParentNodeOfType(state.selection, headingNode, false)
    const isLink = !!linkMark.isInSet(selection.$from.marks())

    // Get the link mark under the cursor if there's one
    if (isLink && allowedPropertyForLink(key)) {
      const link = selection.$from.marks()[0]
      replaceMarkInPosition(state, dispatch, linkMark, {
        ...link.attrs,
        [key]: value,
      })
    } else if (
      replaceMarkInPosition(state, dispatch, markType, { [key]: value })
    ) {
      // 2. If there's cursor. There's mark under the cursor for this type. Remove and add a new mark.
      if (focus) view.focus()
    } else if (paragraph || heading) {
      runCallbacksOnSelectionNodes(view.state, (node, position) => {
        if (node.type == paragraphNode || node.type == headingNode) {
          dispatch(
            view.state.tr.setBlockType(
              position,
              position + node.nodeSize,
              node.type,
              { ...node.attrs, [key]: value }
            )
          )
        }
      })
      if (focus) view.focus()
    }
  }
}

export function runCallbacksOnSelectionNodes(state, cb) {
  let tr = state.tr
  const selection = tr.selection
  state.doc.nodesBetween(selection.from, selection.to, (node, position) => {
    cb(node, position, selection)
  })
}

export function isTextSelection(selection) {
  return (
    selection instanceof TextSelection &&
    selection.$from.pos != selection.$to.pos
  )
}

export function camelize(str) {
  return str.replace(/-(\w)/g, function (str, letter) {
    return letter.toUpperCase()
  })
}

export function titleCaps(text) {
  return toTitleCaps(text)
}

export function hyphenize(str) {
  return str.replace(/([A-Z])/g, function (str, letter) {
    return `-${letter.toLowerCase()}`
  })
}

// https://stackoverflow.com/questions/1955048/get-computed-font-size-for-dom-element-in-js
export function getComputedStyle(el, styleProp) {
  if (el.currentStyle) {
    return el.currentStyle[camelize(styleProp)]
  } else if (document.defaultView && document.defaultView.getComputedStyle) {
    return document.defaultView
      .getComputedStyle(el, null)
      .getPropertyValue(styleProp)
  } else {
    return el.style[camelize(styleProp)]
  }
}

function defaultBlockAt(match) {
  for (let i = 0; i < match.edgeCount; i++) {
    let { type } = match.edge(i)
    if (type.isTextblock && !type.hasRequiredAttrs()) return type
  }
  return null
}

function splitBlockKeepAttributes(state, dispatch) {
  let { $from, $to } = state.selection

  if (
    state.selection instanceof NodeSelection &&
    state.selection.node.isBlock
  ) {
    if (!$from.parentOffset || !canSplit(state.doc, $from.pos)) return false
    if (dispatch) dispatch(state.tr.split($from.pos).scrollIntoView())
    return true
  }

  if (!$from.parent.isBlock) return false

  if (dispatch) {
    let atEnd = $to.parentOffset == $to.parent.content.size
    let tr = state.tr
    if (
      state.selection instanceof TextSelection ||
      state.selection instanceof AllSelection
    )
      tr.deleteSelection()

    let deflt =
      $from.depth == 0
        ? null
        : defaultBlockAt($from.node(-1).contentMatchAt($from.indexAfter(-1)))
    // For paragraphs, use the behavior that copies over paragraph attributes
    atEnd = atEnd && $from.parent.type != deflt

    let types = atEnd && deflt ? [{ type: deflt }] : null
    let can = canSplit(tr.doc, tr.mapping.map($from.pos), 1, types)

    if (
      !types &&
      !can &&
      canSplit(tr.doc, tr.mapping.map($from.pos), 1, deflt && [{ type: deflt }])
    ) {
      types = [{ type: deflt }]
      can = true
    }

    if (can) {
      tr.split(tr.mapping.map($from.pos), 1, types)
      if (!atEnd && !$from.parentOffset && $from.parent.type != deflt) {
        let first = tr.mapping.map($from.before()),
          $first = tr.doc.resolve(first)
        if (
          $from
            .node(-1)
            .canReplaceWith($first.index(), $first.index() + 1, deflt)
        )
          tr.setNodeMarkup(tr.mapping.map($from.before()), deflt)
      }
    }
    dispatch(tr.scrollIntoView())
  }
  return true
}

export function splitBlockWithAttributes(state, dispatch) {
  if (inListItem(state)) return false

  return chainCommands(
    newlineInCode,
    createParagraphNear,
    liftEmptyBlock,
    splitBlockKeepAttributes
  )(state, dispatch)
}

export function isRangeSelection(selection) {
  return selection.from != selection.to
}

export function insertLink(view, href, title = null, attributes = {}) {
  const text = view.state.schema.text(title || href, [
    view.state.schema.marks.link.create({
      ...attributes,
      href,
    }),
  ])

  view.dispatch(view.state.tr.replaceSelectionWith(text, false))
}

// CALVIN: What is this intended to do? And why?
export function linkAttributesFromSelectedMark(state) {
  const selectedNode = state.selection.node
  const rangeSelection = state.selection.from != state.selection.to

  const marks =
    state.selection.node?.marks || rangeSelection
      ? state.selection.$head.marks()
      : state.selection.$from.marks()

  const onlyText = !(
    selectedNode && selectedNode.type == state.schema.nodes.image
  )

  let markAttrs = {}
  let attrs
  let string
  const isOnLink =
    marks && marks.length > 0 && marks[0].type == state.schema.marks.link

  if (isOnLink) {
    markAttrs = marks[0].attrs
    const { from, to } =
      markPosition(state, state.selection.$from.pos, state.schema.marks.link) ||
      {}

    if (!from || !to) {
      return null
    }

    string = state.doc.textBetween(from, to)
  }

  attrs = { onlyText }

  // Get images working with this
  if (isOnLink) {
    attrs.selectedText = onlyText ? string : null
    attrs.selectedAnchor = crel(
      'a',
      {
        href: markAttrs.href,
        title: markAttrs.title,
        target: markAttrs.target,
        class: markAttrs.class,
        'data-magic-login': markAttrs.magicLogin,
        'data-internal-uri': markAttrs.internalUri,
      },
      onlyText ? string : []
    )
  } else if (onlyText && state.selection.$from.pos != state.selection.$to.pos) {
    const string = state.doc.textBetween(
      state.selection.$from.pos,
      state.selection.$to.pos
    )
    attrs.selectedText = string
  }

  return attrs
}

export class ProsemirrorElements {
  constructor(view, input, isInine) {
    this.view = view
    this.input = input
    this.isInline = isInine
    this._menubar = null
  }

  remove() {
    this.menubar.remove()
    this.container.remove()
  }

  get dom() {
    return this.view.dom
  }

  get container() {
    return (
      this._container ||
      (this._container = this.dom.closest('.prosemirror-editor'))
    )
  }

  get containerId() {
    return this.container.getAttribute('id')
  }

  get menubar() {
    return this._menubar || find('.ProseMirror-menubar', this.container)
  }

  get contextMenu() {
    return find('.ProseMirror-context-menu', this.container)
  }

  get emojiPicker() {
    return find('.emoji-picker', this.menubar)
  }

  get emojiMart() {
    return find('.emoji-mart', this.menubar)
  }

  get emojiPickerMenuItem() {
    return find('.prosemirror-emoji > div:first-child', this.menubar)
  }

  inheritedStyleProperties() {
    return [
      'background-color',
      'color',
      'cursor',
      'font-family',
      'font-size',
      'font-style',
      'font-variant',
      'font-weight',
      'line-height',
      'letter-spacing',
      'text-align',
      'text-decoration',
      'text-indent',
      'text-rendering',
      'word-break',
      'word-wrap',
      'word-spacing',
    ]
  }

  inheritedStyleNodeId() {
    return `ProseMirror-inherited-styles-${this.containerId}`
  }

  inheritedStyleNode() {
    return find(`style#${this.inheritedStyleNodeId()}`)
  }

  addInheritedStyles() {
    let node = this.inheritedStyleNode()

    if (!node) {
      node = crel('style', { id: this.inheritedStyleNodeId() })
      document.head.append(node)
    }

    // Input is a jQuery element.
    const styleProperties = Object.entries(
      this.input.css(this.inheritedStyleProperties())
    )
      .map(([key, value]) => `${key}: ${value}`)
      .filter((style) => !!style)
      .join(';')

    node.textContent = `#${this.containerId} .ProseMirror { ${styleProperties} }`
  }

  isMenubarDetached() {
    return this.menubar.parentNode == document.body
  }

  detachMenubar() {
    if (this.isInline && !this.isMenubarDetached()) {
      const menubar = this.menubar

      addClass(menubar, 'ProseMirror-inline-menubar')

      // Remove from its current place
      menubar.remove()

      // Append to document body
      document.body.append(menubar)

      // Store a local reference
      this._menubar = menubar

      // Hide it
      addClass(menubar, 'ProseMirror-menubar-hidden')
    }
  }

  withVisibleElement(el, cb) {
    const oldValue = el.style.display
    el.style.display = 'block'
    cb()
    el.style.display = oldValue
  }

  maxPossibleWidth() {
    const containerRect = this.container.getBoundingClientRect()
    const documentRect = window.document.body.getBoundingClientRect()
    const width =
      documentRect.width - containerRect.x > 900
        ? 900
        : documentRect.width - containerRect.x

    return `${width}px`
  }

  adjustMenubarPosition() {
    const rect = this.container.getBoundingClientRect()
    const y = window.scrollY
    const menubar = this.menubar
    const px = (val) => `${val}px`

    menubar.style.zIndex = 2000
    menubar.style.width = this.maxPossibleWidth()
    menubar.style.top = px(y + rect.y - 80)
    menubar.style.left = px(rect.x)
    menubar.style.position = 'absolute'
  }

  fontFamilyMenuContainer() {
    return find('.ProseMirror-font-select-container', this.menubar)
  }

  fontSizeMenuContainer() {
    return find('.ProseMirror-font-size-menu', this.menubar)
  }

  fontFamilyDropdownShown() {
    const div = find('.selectContainer > div:last-child', this.menubar)
    return div && !hasClass(div, 'indicator')
  }

  fontSizeDropdownShown() {
    const fontSizeMenuContainer = this.fontSizeMenuContainer()
    return (
      fontSizeMenuContainer &&
      hasClass(this.fontSizeMenuContainer(), 'showDropdown')
    )
  }

  resetEmojiPickerPosition() {
    const el = this.emojiPicker
    if (el) {
      el.style.position = 'relative'
      el.style.left = 'initial'
      el.style.top = 'initial'
    }
  }

  hideEmojiPicker() {
    this.menubar.click()
  }

  emojiPickerShown() {
    const emojiMart = this.emojiMart

    if (emojiMart) {
      return emojiMart.style.display !== 'none'
    }

    return false
  }

  editorHasFocus() {
    return (
      this.fontSizeDropdownShown() ||
      this.fontFamilyDropdownShown() ||
      this.emojiPickerShown()
    )
  }

  focus() {
    toggleClass(this.container, 'prosemirror-focussed', true)
    removeClass(this.menubar, 'ProseMirror-menubar-hidden')
    this.adjustMenubarPosition()
  }

  removeFocus() {
    toggleClass(this.container, 'prosemirror-focussed', false)
    addClass(this.menubar, 'ProseMirror-menubar-hidden')
  }

  within_menubar(selector) {
    return find(selector, this.menubar)
  }
}

export { icon, fontAwesomeIcon }
