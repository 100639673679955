import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

const codePattern = /(\{%.+?%})|(\{\{.+?\}\})|(\{%.+$)|(\{\{.+$)/gi

function findInterpolationPositions(doc) {
  let word,
    positions = []
  doc.descendants((node, pos) => {
    if (node.isText) {
      while ((word = codePattern.exec(node.text))) {
        positions.push({
          from: pos + word.index,
          to: pos + word.index + word[0].length,
          text: word[0],
        })
      }
    }
  })
  return positions
}

function getInterpolationClassName(text) {
  if (
    (text.startsWith('{%') && !text.endsWith('%}')) ||
    (text.startsWith('{{') && !text.endsWith('}}'))
  ) {
    return 'prosemirror-interpolation prosemirror-interpolation-error'
  }
  return 'prosemirror-interpolation'
}

function interpolationDecorations(doc) {
  const decorations = []
  findInterpolationPositions(doc).forEach((position) => {
    decorations.push(
      Decoration.inline(position.from, position.to, {
        class: getInterpolationClassName(position.text),
      })
    )
  })
  return DecorationSet.create(doc, decorations)
}

export const interpolationPlugin = new Plugin({
  state: {
    init(_, { doc }) {
      return interpolationDecorations(doc)
    },
    apply(tr, old) {
      return tr.docChanged ? interpolationDecorations(tr.doc) : old
    },
  },
  props: {
    decorations(state) {
      return this.getState(state)
    },
  },
})
