import $ from 'jquery'
import crel from 'crelt'

import { Dialog } from '../dialog'
import { Fragment } from 'prosemirror-model'
import { findTable } from '../utils/find_table'
import { NodeSelection } from 'prosemirror-state'
import { wrapInList } from 'prosemirror-schema-list'
import { buildMenuItems } from 'prosemirror-example-setup'
import { SWITCH_TO_TINYMCE_LABEL } from '../constants'

import { toggleMark, wrapIn, lift, setBlockType } from 'prosemirror-commands'
import {
  find,
  findAll,
  hasClass,
  addClass,
  removeClass,
  toggleClass,
} from '../../../../common/helpers/dom'
import {
  Dropdown,
  blockTypeItem,
  MenuItem,
  undoItem,
  redoItem,
} from 'prosemirror-menu'
import SvelteFontSelect from '@simplero/svelte-font-select'

import {
  camelize,
  titleCaps,
  documentFromHTMLString,
  IconDropdown,
  icon,
  fontAwesomeIcon,
  getPluginState,
  markActive,
  markItem,
  getParentNodeOfType,
  youtubeOrVimeoUrl,
  embedVideoDialog,
  linkAttributesFromSelectedMark,
  removeMarkInPosition,
  replaceMarkInPosition,
  applyProperty,
  runCallbacksOnSelectionNodes,
  placeDropdown,
  ToolbarButton,
} from '../utils'

import {
  toggleList,
  convertList,
  currentListContainer,
  inListItem,
  indentList,
  dedentList,
} from '../utils/list'

import { wysiwygContentTemplatesMenuItem } from './wysiwyg_content_templates'
import { emojiMenuItem } from './emoji_menu_item'

import { editorOptionsKey } from '../plugins/editor_options'

import {
  addColumnAfter,
  addColumnBefore,
  deleteColumn,
  addRowAfter,
  addRowBefore,
  deleteRow,
  mergeCells,
  splitCell,
  toggleHeaderRow,
  toggleHeaderColumn,
  toggleHeaderCell,
  deleteTable,
} from 'prosemirror-tables'

import { track, withTracking } from './../utils/analytics'

const MAX_INDENT = 30
const MIN_FONT_SIZE = 5
const MAX_FONT_SIZE = 150
const DEFAULT_FONT_SIZE = 14

function formatMenuItem(schema) {
  const makeItem = (tag, name, label) => {
    return crel(
      'div',
      { class: `ProseMirror-format-item ProseMirror-format-${name}` },
      [crel(tag, {}, label)]
    )
  }

  const removeFontSizeMarks = (tr, state, dispatch) => {
    const { from, to } = state.selection
    const markType = state.schema.marks.fontSize
    state.doc.nodesBetween(from, to, (node, pos) => {
      if (
        node.isText &&
        node.marks.filter((n) => n.type == markType).length > 0
      ) {
        const rpos = state.doc.resolve(pos)
        if (rpos.start() == pos && rpos.end() == pos + node.nodeSize) {
          tr.removeMark(pos, pos + node.nodeSize, markType)
        }
      }
    })
    dispatch(tr)
  }

  const paragraph = new MenuItem({
    title: 'Paragraph',
    label: 'Paragraph',
    active() {
      return false
    },
    render() {
      return makeItem('p', 'paragraph', 'Paragraph')
    },
    run(state, dispatch, _view) {
      track('Format:Paragraph')
      // Use one transaction to remove marks and replacing block so states
      // dont change between operations
      setBlockType(state.schema.nodes.paragraph, {})(state, (tr) => {
        removeFontSizeMarks(tr, state, dispatch)
      })
    },
  })

  const headings = []
  for (let i = 1; i <= 6; i++) {
    headings.push(
      new MenuItem({
        title: `Heading ${i}`,
        label: `Heading ${i}`,
        attrs: { level: i },
        active() {
          return false
        },
        render() {
          return makeItem('h' + i, `heading heading-${i}`, `Heading ${i}`)
        },
        run(state, dispatch, _view) {
          track(`Format:Heading:${i}`)
          setBlockType(state.schema.nodes.heading, { level: i })(
            state,
            (tr) => {
              removeFontSizeMarks(tr, state, dispatch)
            }
          )
        },
      })
    )
  }

  const blockquote = new MenuItem({
    title: 'Blockquote',
    label: 'Blockquote',
    active() {
      return false
    },
    render() {
      return makeItem('blockquote', 'blockquote', 'Blockquote')
    },
    run(state, dispatch, _view) {
      track('Format:Blockquote')

      const blockquoteNode = state.schema.nodes.blockquote
      const blockquote = getParentNodeOfType(
        state.selection,
        blockquoteNode,
        false
      )

      if (!blockquote) {
        wrapIn(state.schema.nodes.blockquote, {})(state, dispatch)
      } else {
        lift(state, dispatch)
      }
    },
  })

  const preformatted = blockTypeItem(schema.nodes.preformatted, {
    title: 'Preformatted',
    label: 'Preformatted',
    active() {
      return false
    },
    render() {
      return makeItem('pre', 'preformatted', 'Preformatted')
    },
  })

  const codeBlock = new MenuItem({
    title: 'Code',
    label: 'Code',
    active() {
      return false
    },
    render() {
      return makeItem('code', 'code', 'Code')
    },
    run(state, dispatch, _view) {
      track('Format:Code')

      const codeMark = state.schema.marks.code

      if (state.selection.$from.pos != state.selection.$to.pos) {
        toggleMark(codeMark)(state, dispatch)
      } else {
        // Could not remove because no mark existed, create one.
        if (!removeMarkInPosition(state, dispatch, codeMark)) {
          const { $from } = state.selection
          dispatch(
            state.tr.addMark($from.start(), $from.end(), codeMark.create({}))
          )
        }
      }
    },
  })

  const label = crel('div', { class: 'ProseMirror-format-menu' }, [
    crel('strong', {}, 'Paragraph'),
  ])

  return new IconDropdown(
    [paragraph, ...headings, blockquote, preformatted, codeBlock],
    { icon: label, class: 'no-lighter-menu Prosemirror-format-menu-dropdown' }
  )
}

function fontSizeControl(classPrefix, controlType) {
  return crel(
    'div',
    { class: `${classPrefix}-control ${classPrefix}-${controlType}` },
    fontAwesomeIcon(controlType).dom
  )
}

function fontSizeMenuItem(_schema, _options, _menuItems) {
  const menuOptions = {
    run() {
      // Nothing to run
    },
    render(view) {
      const classPrefix = 'ProseMirror-font-size'
      const input = crel('input', { type: 'text', tabindex: '-1' })
      const icon = fontAwesomeIcon('angle-down').dom
      const inputContainer = crel(
        'div',
        { class: `${classPrefix}-input-container` },
        [input]
      )

      // Select the entire text when focussed
      input.addEventListener('focus', () => {
        setTimeout(() => {
          input.select()
        }, 100)
      })

      const increaseFontSizeControl = fontSizeControl(classPrefix, 'plus')
      const decreaseFontSizeControl = fontSizeControl(classPrefix, 'minus')

      const tryFontSize = (size) => {
        return size > MIN_FONT_SIZE && size < MAX_FONT_SIZE
      }

      const modifyFontSize = (cb) => {
        let currentFontSizeValue = parseInt(
          input.value || DEFAULT_FONT_SIZE,
          10
        )
        let newFontSizeValue = parseInt(cb(currentFontSizeValue), 10)

        newFontSizeValue = tryFontSize(newFontSizeValue)
          ? newFontSizeValue
          : currentFontSizeValue

        input.value = newFontSizeValue
        applyProperty('fontSize', `${newFontSizeValue}px`, view)
      }

      increaseFontSizeControl.addEventListener('click', () => {
        modifyFontSize((currentValue) => currentValue + 1)
      })

      decreaseFontSizeControl.addEventListener('click', () => {
        modifyFontSize((currentValue) => currentValue - 1)
      })

      const applySize = (size) => {
        track('FontSize', { size })

        input.value = size
        applyProperty('fontSize', `${size}px`, view)
      }

      input.addEventListener('focus', (e) => {
        e.stopPropagation()
        addClass(input.closest(`.${classPrefix}-menu`), 'showDropdown')
      })

      input.addEventListener('blur', (e) => {
        e.stopPropagation()
        removeClass(input.closest(`.${classPrefix}-menu`), 'showDropdown')
      })

      const form = crel('form', {}, [inputContainer])
      form.addEventListener('submit', (e) => {
        e.preventDefault()
        input.blur()
        applySize(input.value)
      })

      const fontSizes = [
        9, 11, 13, 16, 18, 20, 22, 24, 28, 32, 36, 48, 72, 96, 120,
      ]
      const fontSizeMenu = crel('div', { class: `${classPrefix}-dropdown` }, [
        [
          crel(
            'ul',
            {},
            fontSizes.map((v) => {
              const el = crel('li', {}, `${v}`)
              el.addEventListener('click', (_e) => {
                input.blur()
                applySize(v)
              })

              return el
            })
          ),
        ],
      ])

      const fontSizeInput = crel(
        'div',
        { class: `${classPrefix}-input-container` },
        [form]
      )

      const dom = crel('div', { class: `${classPrefix}-container` }, [
        decreaseFontSizeControl,
        fontSizeInput,
        increaseFontSizeControl,
      ])

      // Don't send this event to menubar, lets just handle this ourselves
      dom.addEventListener('mousedown', (e) => {
        if (e.target.tagName != 'INPUT') e.preventDefault()
        e.stopPropagation()
      })

      icon.addEventListener('click', (_e) => {
        const menu = input.closest(`.${classPrefix}-menu`)
        !hasClass(menu, 'showDropdown') ? input.focus() : input.blur()
      })

      return crel('div', { class: `${classPrefix}-menu` }, [dom, fontSizeMenu])
    },
  }

  return new MenuItem(menuOptions)
}

function underlinedMenuItem(schema, _options, _menuItems) {
  return markItem(
    schema.marks.underline,
    {
      title: 'Underline',
      icon: fontAwesomeIcon('underline'),
      attrs: { underlined: true },
    },
    true
  )
}

function strikeThroughMenuItem(schema, _options, _menuItems) {
  return markItem(
    schema.marks.strikethrough,
    {
      title: 'Strikethrough',
      icon: fontAwesomeIcon('strikethrough'),
      attrs: { strikethrough: true },
    },
    true
  )
}

const transformText = (fn, state, dispatch, unsetNodeAttributes = false) => {
  let tr = state.tr
  const selection = tr.selection
  let shouldUpdate = false

  let resetAttributesForNodes = [
    state.schema.nodes.heading,
    state.schema.nodes.paragraph,
  ]

  state.doc.nodesBetween(selection.from, selection.to, (node, position) => {
    if (
      !node.isTextBlock &&
      resetAttributesForNodes.includes(node.type) &&
      unsetNodeAttributes
    ) {
      // Remove inline styles (color and background color, for example)
      node.descendants((child, pos) => {
        if (child.isText) {
          const start = position + pos + 1
          const end = start + child.nodeSize
          tr = tr.removeMark(start, end)
        }
      })

      tr = tr.setBlockType(
        position,
        position + node.nodeSize,
        node.type,
        node.type === state.schema.nodes.heading
          ? { level: node.attrs.level }
          : {}
      )
      shouldUpdate = true

      return
    }

    if (!node.isTextblock || selection.from === selection.to) return

    const startPosition = Math.max(position + 1, selection.from)
    const endPosition = Math.min(position + node.nodeSize, selection.to)
    const substringFrom = Math.max(0, selection.from - position - 1)
    const substringTo = Math.max(0, selection.to - position - 1)
    const updatedText = node.textContent.substring(substringFrom, substringTo)

    if (updatedText.trim().length > 0) {
      const textNode = state.schema.text(fn(updatedText), node.marks)
      tr = tr.replaceWith(startPosition, endPosition, textNode)
    }

    shouldUpdate = true
  })

  if (dispatch && shouldUpdate) {
    dispatch(tr.scrollIntoView())
  }
}

function titleCaseMenuItem(_schema, _options, _menuItems) {
  const menuOptions = {
    icon: fontAwesomeIcon('font-case'),
    title: 'Titlecase',
    run(state, dispatch) {
      track('Titlecase')
      transformText((text) => titleCaps(text), state, dispatch)
    },
  }

  return new MenuItem(menuOptions)
}

function clearFormattingMenuitem(_schema, _options, _menuItems) {
  const menuOptions = {
    icon: fontAwesomeIcon('text-slash'),
    title: 'Clear formatting',
    run(state, dispatch) {
      track('ClearFormatting')
      transformText((text) => text, state, dispatch, true)
    },
  }

  return new MenuItem(menuOptions)
}

function handleMagicLoginAttrs(linkAttributes) {
  ;[
    ['data-magic-login', 'magicLogin'],
    ['data-internal-uri', 'internalUri'],
  ].forEach(([htmlAttr, proseMirrorAttr]) => {
    if (linkAttributes[htmlAttr]) {
      linkAttributes[proseMirrorAttr] = linkAttributes[htmlAttr]
    }
  })

  return linkAttributes
}

function linkReplacementCallback(state, dispatch, attrs) {
  const schema = state.schema
  const linkType = schema.marks.link
  const anchorType = schema.nodes.anchor

  return (linkAttrs, type) => {
    const isAnchor = linkAttrs.anchor || type === 'anchor'
    const markType = isAnchor ? anchorType : linkType
    linkAttrs = handleMagicLoginAttrs(linkAttrs)

    isAnchor ? track('Anchor') : track('Link')

    if (isAnchor) {
      dispatch(
        state.tr.replaceSelectionWith(markType.create({ id: linkAttrs.id }))
      )
      return
    }

    // Just remove the link
    if (!linkAttrs.href) {
      removeMarkInPosition(state, dispatch, markType)
      return
    }

    if (state.selection.from != state.selection.to) {
      dispatch(
        state.tr.replaceSelectionWith(
          schema.text(linkAttrs.text, [schema.marks.link.create(linkAttrs)]),
          false
        )
      )
    } else {
      if (
        !replaceMarkInPosition(
          state,
          dispatch,
          markType,
          linkAttrs,
          attrs.onlyText ? 'text' : null
        )
      ) {
        dispatch(
          state.tr.replaceSelectionWith(
            schema.text(linkAttrs.text, [schema.marks.link.create(linkAttrs)]),
            false
          )
        )
      }
    }
  }
}

export function showSimpleroLinkDialog(state, dispatch) {
  const attrs = linkAttributesFromSelectedMark(state)
  const editorOptions = getPluginState(state, editorOptionsKey) || {}

  const options = {
    callback: linkReplacementCallback(state, dispatch, attrs),
    resourcesToExclude: editorOptions.uriResourcesToExclude || [],
    contextObjectType: editorOptions.contextObjectType || '',
    ...attrs,
  }

  // Show anchor insert only for a fresh insert, not edits
  if (!attrs?.selectedAnchor) {
    options.allowAnchorInsert = true
  }

  window.top.Simplero.InternalUriDialog.open(options)
}

function simpleroLinkMenuItem(schema) {
  const options = {
    icon: fontAwesomeIcon('link'),
    title: 'Link',
    active(state) {
      return markActive(state, schema.marks.link)
    },
    enable() {
      return true
    },

    run(state, dispatch) {
      track('Simplero link')
      showSimpleroLinkDialog(state, dispatch)
    },
  }

  return new MenuItem(options)
}

function imageAndAnchorNodesFromHTML(state, html) {
  const div = document.createElement('div')
  div.innerHTML = html

  let img = find('img', div)
  if (img) {
    const attributes = [
      'src',
      'alt',
      'title',
      'width',
      'height',
      'data-asset-id',
      'data-audio-asset-id',
      'data-video-asset-id',
      'data-autoplay',
      'data-embedded-video',
    ]

    const imageAttributes = Object.fromEntries(
      attributes.map((attr) => {
        return [camelize(attr.replace('data-', '')), img.getAttribute(attr)]
      })
    )

    const alignment =
      img.getAttribute('align') ||
      (img.style.float == 'left' || img.style.float == 'right'
        ? img.style.float
        : null)

    imageAttributes.alignment = alignment === 'middle' ? 'center' : alignment

    img = state.schema.nodes.image.create(imageAttributes)
  }

  let link = find('a', div)
  if (link) {
    const attributes = ['class', 'target', 'href', 'title']
    link = state.schema.marks.link.create(
      Object.fromEntries(
        attributes.map((attr) => [attr, link.getAttribute(attr)])
      )
    )

    if (img) {
      img.mark([link])
    }
  }

  return img
}

function assetMenuItem(_schema, _options, _menuItems) {
  const menuOptions = {
    icon: fontAwesomeIcon('images'),
    title: 'Media library',
    run(state, dispatch) {
      track('Media library')
      const editorOptions = getPluginState(state, editorOptionsKey) || {}
      const options = {
        mode: 'html',
        mediaType: editorOptions.mediaType || '',
        callback: (_, html) => {
          const img = imageAndAnchorNodesFromHTML(state, html)
          dispatch(state.tr.insert(state.selection.head, img))
        },
      }
      window.top.Simplero.AssetsDialog.open(options)
    },
  }

  return new MenuItem(menuOptions)
}

function canInsert(state, nodeType) {
  let $from = state.selection.$from
  for (let d = $from.depth; d >= 0; d--) {
    let index = $from.index(d)
    if ($from.node(d).canReplaceWith(index, index, nodeType)) return true
  }
  return false
}

function imageMenuItem(_schema, _options, _menuItems) {
  return new MenuItem({
    title: 'Insert image',
    icon: fontAwesomeIcon('image'),
    enable(state) {
      return canInsert(state, state.schema.nodes.image)
    },
    run(state, _, view) {
      track('Image')
      let attrs = null

      const nodeType = state.schema.nodes.image

      if (
        state.selection instanceof NodeSelection &&
        state.selection.node.type == nodeType
      )
        attrs = state.selection.node.attrs

      const callback = (values) => {
        view.dispatch(
          view.state.tr.replaceSelectionWith(nodeType.createAndFill(values))
        )
        view.focus()
      }

      const fields = [
        { type: 'text', name: 'src', label: 'URL', value: attrs && attrs.src },
        {
          type: 'text',
          name: 'alt',
          label: 'Image Description',
          value: attrs && attrs.alt,
        },
        {
          type: 'group',
          label: 'Dimensions',
          fields: [
            {
              type: 'number',
              name: 'width',
              label: 'Width',
              value: attrs && attrs.width,
            },
            { type: 'label', text: 'x' },
            {
              type: 'number',
              name: 'height',
              label: 'Height',
              value: attrs && attrs.height,
            },
          ],
        },
      ]

      new Dialog('image', 'Image', fields, callback).show()
    },
  })
}

function shortcutForAlignment(alignType) {
  const mapping = {
    left: '⌘  ⇧  Y',
    right: '⌘  ⇧  R',
    center: '⌘  ⇧  E',
    justify: '⌘  ⇧  J',
  }

  return mapping[alignType]
}

const applyAlignment = (view, alignmentCb) => {
  runCallbacksOnSelectionNodes(view.state, (node, position) => {
    const alignType = alignmentCb(node, position)
    const isNodeSelected = !!view.state.selection.node
    const newAlignType =
      alignType === node.attrs.alignment && alignType === 'left'
        ? null
        : alignType

    if (['paragraph', 'heading', 'image'].includes(node.type.name)) {
      if (
        node.type.name === 'image' ||
        (isNodeSelected && node === view.state.selection.node)
      ) {
        if (alignType !== 'justify') {
          view.dispatch(
            view.state.tr.replaceWith(
              position,
              position + node.nodeSize,
              view.state.schema.nodes.image.create({
                ...node.attrs,
                alignment: newAlignType,
              })
            )
          )
        }
      } else if (!isNodeSelected) {
        view.dispatch(
          view.state.tr.setBlockType(
            position,
            position + node.nodeSize,
            node.type,
            {
              ...node.attrs,
              alignment: newAlignType,
            }
          )
        )
      }
    }
  })
}

export function textAlign(alignType, view) {
  applyAlignment(view, (_) => alignType)
}

function alignmentMenuItem(alignType) {
  const options = {
    icon: fontAwesomeIcon(`align-${alignType}`),
    title: `Align ${alignType}`,
    render() {
      return crel('div', { class: 'ProseMirror-align-item' }, [
        fontAwesomeIcon(`align-${alignType}`).dom,
        crel('span', {}, alignType),
        crel('span', {}, shortcutForAlignment(alignType)),
      ])
    },
    run(_state, _dispatch, view) {
      track('TextAlign', { align: alignType })
      textAlign(alignType, view)
    },
  }

  return new MenuItem(options)
}

function textAlignmentMenuItem(_schema, _options, _menuItems) {
  const alignmentOrder = ['left', 'center', 'right', 'justify']

  const getNextAlignment = (currentAlignment) => {
    if (!currentAlignment) return alignmentOrder[0]

    const index = alignmentOrder.indexOf(currentAlignment)
    if (index < 0 || index === alignmentOrder.length - 1) {
      return alignmentOrder[0]
    }

    return alignmentOrder[index + 1]
  }

  const clickHandler = (view) => {
    applyAlignment(view, (node) => {
      const currentAlignment = node.attrs.alignment
      return getNextAlignment(currentAlignment)
    })
  }

  const menuOptions = {
    icon: fontAwesomeIcon('align-left').dom,
    title: 'Alignment',
    class: 'ProseMirror-text-alignment',
    clickHandler,
  }

  const items = [
    alignmentMenuItem('left'),
    alignmentMenuItem('center'),
    alignmentMenuItem('right'),
    alignmentMenuItem('justify'),
  ]

  return new IconDropdown(items, menuOptions)
}

function listDropdown(listType, title, iconClass, classPrefix, items = []) {
  const options = {
    title,
    render(view) {
      let classNameFor = (classSuffix) =>
        `ProseMirror-command-dropdown-${classSuffix}`

      let command = crel('div', { class: classNameFor('command') }, [
        fontAwesomeIcon(iconClass).dom,
      ])

      const apply = (attrs, toggle) => {
        const currentList = currentListContainer(view)
        const { state, dispatch } = view

        track(title.replace(/\s+/, ''))

        if (!currentList || !attrs) {
          return wrapInList(state.schema.nodes[listType], attrs)(
            state,
            dispatch
          )
        } else if (toggle && listType == currentList.node.type.name) {
          toggleList(listType, attrs)(state, dispatch)
        } else {
          convertList(listType, currentList, attrs)(state, dispatch)
        }
      }

      let commandItems = items.map((commandItem) => {
        const li = crel('li', { class: `${classPrefix}-${commandItem}` })

        // Actual list item was clicked
        li.addEventListener('click', (_e) => {
          apply({
            class: `prosemirror-list prosemirror-${classPrefix}-${commandItem}`,
          })
        })

        return li
      })

      let arrowDown = fontAwesomeIcon('angle-down').dom
      let dropdown = crel(
        'ul',
        { class: classNameFor('dropdown') + ' ' + classNameFor(listType) },
        commandItems
      )
      let container = crel('div', { class: classNameFor('container') }, [
        command,
        crel('div', { class: classNameFor('dropdown-container') }, [
          arrowDown,
          dropdown,
        ]),
      ])

      command.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        apply({ class: 'prosemirror-list' }, true)
      })

      arrowDown.addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation()

        // Remove any earlier dropdowns that are still showing
        Array.from(
          findAll('.' + classNameFor('dropdown-container') + ' > ul', document)
        ).forEach((el) => removeClass(el, 'show-dropdown'))

        toggleClass(dropdown, 'show-dropdown', true)
        placeDropdown(dropdown)
      })

      window.addEventListener('click', (_e) => {
        toggleClass(dropdown, 'show-dropdown', false)
      })

      return container
    },
    run(state, _dispatch) {
      return wrapInList(state.schema.nodes[listType], {})
    },
  }

  return new MenuItem(options)
}

function bulletListMenuItem(_schema, _options, _menuItems) {
  return listDropdown('bullet_list', 'Bulleted list', 'list', 'ul', [
    'bullet-hollow-square',
    'crossed-diamond-threed-arrow-square',
    'tick-box',
    'arrow-diamond-bullet',
    'star-hollow-square',
    'threed-arrow-hollow-square',
  ])
}

function orderedListMenuItem(_schema, _options, _menuItems) {
  return listDropdown('ordered_list', 'Numbered list', 'list-ol', 'ol', [
    'number-lower-alpha-lower-roman',
    'upper-alpha-lower-alpha-lower-roman',
    'upper-roman-upper-alpha-number',
  ])
}

function canIndent(node) {
  return node.type.name === 'paragraph' || node.type.name === 'heading'
}

function setIndent(node, position, state, dispatch, valueCb) {
  if (!canIndent(node)) {
    return
  }

  const { indent } = node.attrs
  let newIndent = valueCb(indent)
  newIndent =
    newIndent === null || newIndent <= 0
      ? null
      : newIndent > MAX_INDENT
      ? MAX_INDENT
      : newIndent
  dispatch(
    state.tr.setBlockType(position, position + node.nodeSize, node.type, {
      ...node.attrs,
      indent: newIndent,
    })
  )
}

export function indent(state, dispatch) {
  if (inListItem(state)) {
    indentList(state, dispatch)
  } else {
    runCallbacksOnSelectionNodes(state, (node, position) => {
      setIndent(
        node,
        position,
        state,
        dispatch,
        (value) => (value && value + 1) || 1
      )
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function indentMenuItem() {
  const options = {
    icon: icon(null, 'mce-ico mce-i-indent'),
    title: 'Indent',
    run: indent,
  }
  return new MenuItem(options)
}

export function outdent(state, dispatch) {
  if (inListItem(state)) {
    dedentList(state, dispatch)
  } else {
    runCallbacksOnSelectionNodes(state, (node, position) => {
      setIndent(node, position, state, dispatch, (value) => value && value - 1)
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function outdentMenuItem() {
  const options = {
    icon: icon(null, 'mce-ico mce-i-outdent'),
    title: 'Outdent',
    run: outdent,
  }
  return new MenuItem(options)
}

function textColorMenuItem(_schema, _options, _menuItems) {
  const menuOptions = {
    icon: fontAwesomeIcon('font').dom,
    title: 'Text color',
    class: 'prosemirror-font-color',
    key: 'fontColor',
  }

  return new ToolbarButton(
    document.getElementById('swatch-color-picker-template').innerHTML,
    menuOptions
  )
}

function textBackgroundColorMenuItem(_schema, _options, _menuItems) {
  const menuOptions = {
    icon: fontAwesomeIcon('pen-nib').dom,
    title: 'Background color',
    class: 'prosemirror-font-background-color',
    key: 'fontBackgroundColor',
  }

  return new ToolbarButton(
    document.getElementById('swatch-color-picker-template').innerHTML,
    menuOptions
  )
}

function horizontalRuleMenuItem(_schema, _options, _menuItems) {
  const run = (state, dispatch) => {
    dispatch(
      state.tr.replaceSelectionWith(state.schema.nodes.horizontal_rule.create())
    )
  }

  const menuOptions = {
    icon: fontAwesomeIcon('horizontal-rule'),
    title: 'Horizontal rule',
    run: withTracking('HorizontalRule', {}, run),
  }
  return new MenuItem(menuOptions)
}

function unsetGlobalInsertCallback() {
  delete window.prosemirror_editor_insert_callback
}

export function preparePersonalizationText(key, label, cb) {
  switch (key) {
    case 'content_url':
      cb(`<p><a href='{{content_url}}' class='btn'>${label}</a></p>`, true)
      break
    case 'countdown_timer':
      // Hacky!!
      window.prosemirror_editor_insert_callback = (img) => {
        cb(img, true)
        unsetGlobalInsertCallback()
      }

      $.sajax({
        type: 'GET',
        url: '/admin/account/countdown_timer_modal',
      })
      break
    case 'if_customer_tags':
      cb(
        "<p>{% if customer.tags contains 'My tag' %}<br>The contact totally has this tag.<br>{% else %}<br>Nope, the contact doesn't have the tag.<br>{% endif %}</p>",
        true
      )
      break
    case 'if_customer':
      cb(
        "<p>{% if customer %}<br>Hello, {{ customer.first_names }}!<br>{% else %}<br>Don't know you.<br>{% endif %}</p>",
        true
      )
      break
    case 'if_logged_in':
      cb(
        '<p>{% if app.logged_in? %}<br>You\'re totally logged in, {{ current_user.first_names }}.<br>{% else %}<br>Not logged in today.<br>{% endif %}</p>"',
        true
      )
      break
    case 'warning-box':
      cb(
        "<p class='simplero--warning-box'>Type your warning here&hellip;</p>",
        true
      )
      break
    case 'notice-box':
      cb(
        '<div class="notice-box"><p>Type your text here&hellip;</p></div><p>&nbsp;</p>',
        true
      )
      break
    case 'credit_balance':
      cb('{{ customer.credits.CREDITID.balance }}', false)
      break
    case 'credit_next_expiration_date':
      cb(
        '{{ customer.credits.CREDITID.next_expiration_date | date: "%b %-d, %Y" }}',
        false
      )
      break
    case 'credit_next_expiration_amount':
      cb('{{ customer.credits.CREDITID.next_expiration_amount }}', false)
      break
    default:
      cb(`{{${key}}}`, false)
  }
}

function prepareContent(personalizations) {
  return personalizations.map(
    (v) =>
      new MenuItem({
        render() {
          const node = crel('div', null)
          node.innerHTML = v[1] || v[0]
          return node
        },
        run(state, dispatch) {
          track('Personalization:Menubar', { key: v[0] })
          preparePersonalizationText(v[0], v[1], (text, isHtml) => {
            if (isHtml) {
              const fragment = documentFromHTMLString(text)
              dispatch(state.tr.replaceSelectionWith(fragment))
            } else {
              dispatch(state.tr.replaceSelectionWith(state.schema.text(text)))
            }
          })
        },
      })
  )
}

class PersonalizationDropdown extends Dropdown {
  constructor(personalizations) {
    super(prepareContent(personalizations), {
      label: fontAwesomeIcon('bracket-curly').dom,
      class: 'no-dropdown-indicator',
    })
    this.personalizations
  }

  select(_state) {
    return !!this.personalizations
  }
}

function personalizationMenuItem(_schema, options, _menuItems) {
  if (options && options.personalizations) {
    return new PersonalizationDropdown(options.personalizations)
  }

  return null
}

function times(n, cb) {
  const result = []
  for (let i = 0; i < n; i++) {
    result.push(cb(i))
  }
  return result
}

function createTable(view, rows, cols) {
  const tr = view.state.tr
  const schema = view.state.schema

  const newTr = tr.replaceSelectionWith(
    schema.nodes.table.create(
      undefined,
      Fragment.fromArray(
        times(rows, () => {
          return schema.nodes.table_row.create(
            undefined,
            Fragment.fromArray(
              times(cols, () => schema.nodes.table_cell.createAndFill())
            )
          )
        })
      )
    )
  )

  view.dispatch(newTr)
}

function adjustTableProperties(state, dispatch, view) {
  const table = findTable(state.selection)

  if (!table) {
    return
  }

  const tableAttrs = table.node.attrs
  const fields = [
    {
      type: 'number',
      name: 'width',
      label: 'Width',
      value: tableAttrs && tableAttrs.width,
    },
    {
      type: 'number',
      name: 'height',
      label: 'Height',
      value: tableAttrs && tableAttrs.height,
    },
    {
      type: 'number',
      name: 'cellspacing',
      label: 'Cell spacing',
      value: tableAttrs && tableAttrs.cellspacing,
    },
    {
      type: 'number',
      name: 'cellpadding',
      label: 'Cell padding',
      value: tableAttrs && tableAttrs.cellpadding,
    },
    {
      type: 'number',
      name: 'border',
      label: 'Border',
      value: tableAttrs && tableAttrs.border,
    },
    {
      type: 'color',
      name: 'borderColor',
      label: 'Border color',
      value: tableAttrs && tableAttrs.borderColor,
    },
    {
      type: 'color',
      name: 'backgroundColor',
      label: 'Background color',
      value: tableAttrs && tableAttrs.backgroundColor,
    },
  ]

  const callback = (values) => {
    // Not sure why findTable returns a first row's position, not really table's
    const tr = state.tr
    tr.setNodeMarkup(table.pos - 1, table.node.type, values)
    tr.scrollIntoView()
    dispatch(tr)
    view.focus()
  }

  new Dialog('table', 'Table', fields, callback).show()
}

function domForOptions(options, view, menuContainer) {
  return crel(
    'div',
    {},
    options.map((option) => {
      const el = crel('div', { class: 'table-option' }, option.label)
      el.addEventListener('click', (_e) => {
        option.cmd(view.state, view.dispatch)
        removeClass(menuContainer, 'show')
      })
      return el
    })
  )
}

function cellOptions(view, menuContainer) {
  const options = [
    { label: 'Merge cells', value: 'mergeCells', cmd: mergeCells },
    { label: 'Split cell', value: 'splitCell', cmd: splitCell },
    {
      label: 'Toggle header cells',
      value: 'toggleHeaderCell',
      cmd: toggleHeaderCell,
    },
  ]
  return domForOptions(options, view, menuContainer)
}

function rowOptions(view, menuContainer) {
  const options = [
    { label: 'Insert row before', value: 'addRowBefore', cmd: addRowBefore },
    { label: 'Insert row after', value: 'addRowAfter', cmd: addRowAfter },
    { label: 'Delete row', value: 'deleteRow', cmd: deleteRow },
    {
      label: 'Toggle header row',
      value: 'toggleHeaderRow',
      cmd: toggleHeaderRow,
    },
  ]

  return domForOptions(options, view, menuContainer)
}

function columnOptions(view, menuContainer) {
  const options = [
    {
      label: 'Add column before',
      value: 'addColumnBefore',
      cmd: addColumnBefore,
    },
    { label: 'Add column after', value: 'addColumnAfter', cmd: addColumnAfter },
    { label: 'Delete column', value: 'deleteColumn', cmd: deleteColumn },
    {
      label: 'Toggle header column',
      value: 'toggleHeaderColumn',
      cmd: toggleHeaderColumn,
    },
  ]
  return domForOptions(options, view, menuContainer)
}

function tableMenuItem(_schema, _options, _menuItems) {
  let menuOptions = {
    icon: icon(null, 'mce-ico mce-i-table'),
    title: 'Table',
    render(view) {
      const menuContainer = crel('div', {
        class: 'ProseMirror-table-menu__container',
      })
      const selectedSize = crel('div', {
        class: 'ProseMirror-table-menu__selected-size',
      })

      // Create table
      const rows = 10
      const cols = 10
      const rowHtml = []
      for (let i = 0; i < rows; i++) {
        const colHtml = []
        for (let j = 0; j < cols; j++) {
          const col = crel('div', {
            class: `prosemirror-table-column col-${i}-${j}`,
            'data-row': i + 1,
            'data-col': j + 1,
          })
          col.addEventListener('click', (e) => {
            e.preventDefault()
            e.stopPropagation()
            track('Table')
            createTable(view, i + 1, j + 1)
          })

          col.addEventListener('mouseenter', (_e) => {
            $('.prosemirror-table-column').removeClass('active')
            for (let p = 0; p <= i; p++) {
              for (let q = 0; q <= j; q++) {
                $(`.col-${p}-${q}`).addClass('active')
              }
            }

            selectedSize.innerHTML = `${i + 1} x ${j + 1}`
          })

          colHtml.push(col)
        }
        rowHtml.push(crel('div', { class: 'prosemirror-table-row' }, colHtml))
      }

      const tableSelector = crel('div', {}, [
        crel('div', { class: 'table-size-selector' }, rowHtml),
        selectedSize,
      ])

      // Modify tables
      const tableOptions = [
        { label: 'Table', dom: tableSelector },
        {
          label: 'Table properties',
          value: 'tableProperties',
          cmd: adjustTableProperties,
        },
        { label: 'Delete table', value: 'deleteTable', cmd: deleteTable },
        {},
        { label: 'Cell', dom: cellOptions(view, menuContainer) },
        { label: 'Row', dom: rowOptions(view, menuContainer) },
        { label: 'Column', dom: columnOptions(view, menuContainer) },
      ]

      const tableMenu = tableOptions.map((v) => {
        const hasSubmenu = v.dom
        const isHr = !v.label
        const klass = `table-option ${
          hasSubmenu
            ? 'table-option__submenu'
            : isHr
            ? 'table-option__hr'
            : null
        }`

        let contents
        if (hasSubmenu) {
          const label = crel('div', { class: 'submenu-label' }, [
            v.label,
            crel('i', { class: 'fa fa-caret-right' }),
          ])
          const submenuContainer = crel(
            'div',
            { class: 'submenu-container' },
            v.dom
          )

          let withinSubMenu = false
          let timeout

          label.addEventListener('mouseenter', function (_e) {
            addClass(submenuContainer, 'show')
          })

          label.addEventListener('mouseleave', function (_e) {
            if (timeout) {
              clearTimeout(timeout)
            }

            timeout = setTimeout(() => {
              if (!withinSubMenu) {
                removeClass(submenuContainer, 'show')
              }
            }, 300)
          })

          submenuContainer.addEventListener('mouseenter', function (e) {
            e.stopPropagation()
            withinSubMenu = true
          })

          submenuContainer.addEventListener('mouseleave', function (e) {
            e.stopPropagation()
            withinSubMenu = false
            removeClass(submenuContainer, 'show')
          })

          contents = [crel('div', {}, [label, submenuContainer])]
        } else if (isHr) {
          contents = []
        } else {
          contents = v.label
        }

        const option = crel(
          'div',
          { class: klass, 'data-value': v.value },
          contents
        )

        if (v.cmd && !hasSubmenu && !isHr) {
          option.addEventListener('click', (e) => {
            e.preventDefault()
            v.cmd(view.state, view.dispatch, view)
            removeClass(menuContainer, 'show')
          })
        }

        return option
      })

      const tableOptionsContainer = crel(
        'div',
        { class: 'ProseMirror-table-menu__options-container' },
        tableMenu
      )

      const icon = crel(
        'div',
        { class: 'ProseMirror-icon ProseMirror-table-menu' },
        [fontAwesomeIcon('table').dom, menuContainer]
      )

      icon.addEventListener('click', function (e) {
        e.preventDefault()
        e.stopPropagation()
        addClass(menuContainer, 'show')
        placeDropdown(tableOptionsContainer)

        menuContainer.replaceChildren(crel('div', {}, [tableOptionsContainer]))
      })

      document.addEventListener('click', function (_e) {
        removeClass(menuContainer, 'show')
      })

      return icon
    },
    run() {
      // Nothing to run
    },
  }

  return new MenuItem(menuOptions)
}

function videoEmbedAttributes(imageNode, anchorNode) {
  if (!anchorNode) {
    return {}
  }

  if (!youtubeOrVimeoUrl(anchorNode.attrs.href)) {
    return {}
  }

  return {
    video_url: anchorNode.attrs.href,
    alt: imageNode.attrs.alt,
    width: imageNode.attrs.width,
    height: imageNode.attrs.height,
    id: imageNode.attrs.assetId,
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function embeddedVideoMenuItem() {
  const options = {
    icon: icon(null, 'mce-ico mce-i-media'),
    title: 'Embed video',
    run(state, _dispatch, view) {
      let imageNode = state.selection.node
      let anchorNode
      let attrs

      if (imageNode && imageNode.type == state.schema.nodes.image) {
        anchorNode = getParentNodeOfType(
          state.selection,
          state.schema.marks.link,
          true
        )
        attrs = videoEmbedAttributes(imageNode, anchorNode)
      } else {
        attrs = {}
      }

      embedVideoDialog(attrs, view, imageNode && imageNode.attrs.src)
    },
  }
  return new MenuItem(options)
}

function lineHeightItem(label, size) {
  return new MenuItem({
    label,
    title: label,
    run(state, _dispatch, view) {
      if (size === null) {
        const fields = [
          {
            type: 'number',
            name: 'lineHeight',
            label: 'Line height',
            value: state.doc.firstChild.attrs.lineHeight || null,
          },
        ]

        const callback = ({ lineHeight }) => {
          track('LineHeight', { size })
          applyProperty('lineHeight', lineHeight, view)
        }

        new Dialog('lineHeight', 'Line height', fields, callback).show()
      } else {
        track('Line height', { size })
        applyProperty('lineHeight', size, view)
      }
    },
  })
}

function lineHeightMenuItem(_schema, _options, _menuItems) {
  const menuOptions = {
    icon: fontAwesomeIcon('line-height').dom,
    title: 'Line height',
    class: 'ProseMirror-line-height-menu-item',
  }
  return new IconDropdown(
    [
      lineHeightItem('Default', 1.4),
      lineHeightItem('Single', 1),
      lineHeightItem('Double', 2),
      lineHeightItem('Custom', null),
    ],
    menuOptions
  )
}

let fontSelectId = 1
function fontFamilyMenuItem(_schema, options, _menuItems) {
  if (!options.fonts) {
    return null
  }

  const menuOptions = {
    run() {
      // Nothing to run, but needed.
    },
    render(view) {
      const fonts = options.fonts.map((font) => ({
        group: 'fonts',
        label: font.name,
        name: font.key,
        value: font.key,
        extraData: {
          cssString: font.css,
        },
      }))

      const selectContainerId = `font-select-${fontSelectId++}`
      const selectContainer = crel('div', { id: selectContainerId })
      const selectWidget = new SvelteFontSelect({
        target: selectContainer,
        props: {
          items: fonts,
          groupBy: (o) => o.group,
          onSelect: (e) => {
            track('Font', { font: e.name })
            applyProperty('fontFamily', e.extraData.cssString, view)
          },
          tabindex: -1,
        },
      })

      const container = crel(
        'div',
        {
          class:
            'ProseMirror-font-select-container ProseMirror-menu-dropdown-wrap',
        },
        [selectContainer]
      )

      // Keep a reference
      container.selectWidget = selectWidget
      container.fonts = fonts

      return container
    },
  }

  return new MenuItem(menuOptions)
}

function switchToTinyMCEMenuItem(_schema, options, _menuItems) {
  // Only for inline editors
  if (!options.inline) {
    return null
  }

  return new MenuItem({
    title: SWITCH_TO_TINYMCE_LABEL,
    label: SWITCH_TO_TINYMCE_LABEL,
    class: 'ProseMirror-editor-switch',
    run(_state, _dispatch, view) {
      // Only works on inline editors
      if (!options.inline) {
        return
      }

      // Get the prosemirror instance
      const editorContainer = view.dom.closest('.prosemirror-editor')

      const id = editorContainer.getAttribute('id')
      const el = editorContainer.closest(
        '[data-simplero-setting-type="wysiwyg"]'
      )
      const connector = window.simpleroSiteEditorAdminConnector
      const currentEditor = window.proseMirror.get(id)

      const textarea = find('textarea', el)
      if (currentEditor && connector && el && textarea) {
        // Set the current value, that may be used by tinyMCE editor later;
        textarea.value = currentEditor.getValue()

        // Remove
        window.proseMirror.remove(id)

        // Setup tinyMCE editor
        connector._setupTinyMCE($(el), id, $(textarea))
      }
      // Embedded editor
      else if (currentEditor && !textarea) {
        const el = document.getElementById(window.proseMirror.editorKey(id))

        if (el) {
          el.innerHTML = currentEditor.getValue()
          window.proseMirror.remove(id)
          el.style.display = 'block'
          // Install tinyMCE editor
          $(el).changed()
        }
      }
    },
  })
}

function infoMenuItem(_schema, _options, _menuitems) {
  const menuOptions = {
    icon: fontAwesomeIcon('circle-info'),
    title: 'Editor info',
    render() {
      const keyboardShortcuts = [
        ['Cmd + Z', 'Undo'],
        ['Cmd + ⇧ + Z', 'Redo'],
        ['Cmd + ]', 'Indent'],
        ['Cmd + [', 'Outdent'],
        ['Cmd + e', 'Insert emoji'],
        ['{{', 'Insert'],
      ]

      const rows = keyboardShortcuts.map((shortCut) =>
        crel('tr', {}, [
          crel('th', {}, [
            crel('span', { class: 'ProseMirror-info__command' }, shortCut[0]),
          ]),
          crel('td', {}, shortCut[1]),
        ])
      )

      const dropdown = crel('div', { class: 'ProseMirror-info__table' }, [
        crel(
          'p',
          { class: 'ProseMirror-info__description' },
          'Use these quick shortcuts to do even more'
        ),
        crel('table', {}, rows),
      ])

      const el = crel('div', { class: 'ProseMirror-info' }, [
        fontAwesomeIcon('circle-info').dom,
        dropdown,
      ])

      const show = (e) => {
        e.preventDefault()
        e.stopPropagation()
        addClass(dropdown, 'showing')
      }

      const hide = (_e) => {
        removeClass(dropdown, 'showing')
      }

      el.addEventListener('mouseenter', show)
      el.addEventListener('click', show)
      el.addEventListener('mouseleave', hide)
      window.document.addEventListener('click', hide)

      return el
    },
    run() {
      // Nothing to run
    },
  }
  return new MenuItem(menuOptions)
}

function strongMenuItem(_schema, _options, menuItems) {
  const strong = menuItems.toggleStrong

  strong.spec.icon = fontAwesomeIcon('bold')
  strong.spec.label = strong.spec.title = 'Bold'
  strong.spec.run = withTracking('Strong', {}, strong.spec.run)

  return strong
}

function emphasisedMenuItem(_schema, _options, menuItems) {
  const em = menuItems.toggleEm

  em.spec.label = em.spec.title = 'Italic'
  em.spec.icon = fontAwesomeIcon('italic')
  em.spec.run = withTracking('Em', {}, em.spec.run)

  return em
}

export function buildMenu(schema, options, type) {
  const menuItems = buildMenuItems(schema)

  // Use tinymce icons for some of buttons from prosemirror-example-setup
  undoItem.spec.icon = icon(null, 'mce-ico mce-i-undo')
  redoItem.spec.icon = icon(null, 'mce-ico mce-i-redo')

  const menuMapping = {
    personalization: personalizationMenuItem,
    format: formatMenuItem,
    titleCase: titleCaseMenuItem,
    fontFamily: fontFamilyMenuItem,
    fontSize: fontSizeMenuItem,
    lineHeight: lineHeightMenuItem,
    strong: strongMenuItem,
    em: emphasisedMenuItem,
    underline: underlinedMenuItem,
    strikeThrough: strikeThroughMenuItem,
    textAlignment: textAlignmentMenuItem,
    bulletList: bulletListMenuItem,
    orderedList: orderedListMenuItem,
    textColor: textColorMenuItem,
    textBackgroundColor: textBackgroundColorMenuItem,
    link: simpleroLinkMenuItem,
    asset: assetMenuItem,
    image: imageMenuItem,
    table: tableMenuItem,
    horizontalRule: horizontalRuleMenuItem,
    clearFormatting: clearFormattingMenuitem,
    info: infoMenuItem,
    wysiwygContentTemplates: wysiwygContentTemplatesMenuItem,
    switchToTinyMCE: switchToTinyMCEMenuItem,
    emoji: emojiMenuItem,
  }

  let menu
  let floatingMenu

  const wysiwygContentTemplates = options.enableWysiwygContentTemplates
    ? 'wysiwygContentTemplates '
    : ''

  if (type === 'admin' || options.admin) {
    if (options.inline) {
      menu = [
        ['personalization'],
        ['format'],
        'fontFamily fontSize lineHeight strong em underline strikeThrough'.split(
          ' '
        ),
        ['textAlignment'],
        'bulletList orderedList'.split(' '),
        'textColor textBackgroundColor'.split(' '),
        'link asset image emoji'.split(' '),
        'table horizontalRule'.split(' '),
        `clearFormatting ${wysiwygContentTemplates}info switchToTinyMCE`.split(
          ' '
        ),
      ]
    } else {
      menu = [
        ['personalization'],
        ['format'],
        'fontFamily fontSize lineHeight'.split(' '),
        'textColor textBackgroundColor'.split(' '),
        'link asset image emoji'.split(' '),
        'table horizontalRule'.split(' '),
        `${wysiwygContentTemplates}info switchToTinyMCE`.split(' '),
      ]

      floatingMenu = [
        'strong em underline strikeThrough titleCase'.split(' '),
        ['textAlignment'],
        'bulletList orderedList'.split(' '),
        ['link'],
        ['clearFormatting'],
      ]
    }
  } else {
    menu = [
      ['personalization'],
      'strong em underline strikeThrough'.split(' '),
      ['textAlignment'],
      'link image'.split(' '),
      'bulletList orderedList'.split(' '),
      `clearFormatting ${wysiwygContentTemplates}info switchToTinyMCE`.split(
        ' '
      ),
    ]
  }

  const menuItemInstances = (list) => {
    if (!list) {
      return
    }

    return list
      .map((group) => {
        return group
          .map((menuItem) => menuMapping[menuItem](schema, options, menuItems))
          .filter((menuItem) => !!menuItem)
      })
      .filter((group) => group && group.length > 0)
  }

  return [menuItemInstances(floatingMenu), menuItemInstances(menu)]
}
