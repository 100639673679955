import { Fragment, NodeRange, Slice } from 'prosemirror-model'

import { liftTarget, ReplaceAroundStep } from 'prosemirror-transform'
import { liftListItem, wrapInList } from 'prosemirror-schema-list'
import { TextSelection } from 'prosemirror-state'
import { joinBackward } from 'prosemirror-commands'

export function isDocumentNode(node) {
  return Boolean(node && node.type && node.type.name === 'doc')
}

export function isListNode(node) {
  return Boolean(
    node &&
      node.type &&
      ['ordered_list', 'bullet_list'].includes(node.type.name)
  )
}

export function isParagraphNode(node) {
  return Boolean(node && node.type && 'paragraph' === node.type.name)
}

export function isListItemNode(node) {
  return Boolean(node && node.type && 'list_item' === node.type.name)
}

export function isBulletList(node) {
  return Boolean(node && node.type && 'bullet_list' === node.type.name)
}

export function inListItem(state) {
  const grandParent = state.selection.$from.node(-1)
  return grandParent && grandParent.type == state.schema.nodes.list_item
}

export function indentList(state, dispatch) {
  const { $from } = state.selection

  if (!inListItem(state)) {
    return false
  }

  const greatGrandParent = $from.node(-2)
  // Only list item and no content. Don't do anything.
  if (greatGrandParent.childCount == 1) {
    return true
  }

  // 2. Wrap in list otherwise
  return wrapInList(greatGrandParent.type, {})(state, dispatch)
}

export function dedentListHelper(state, dispatch, $from, $to) {
  // range
  let range = $from.blockRange($to)

  // li
  const grandParent = $from.node(-1)

  // nodes.list_item
  const itemType = grandParent.type

  // First level list item, move it out.
  if (range.depth === 2) {
    return liftListItem(grandParent.type)(state, dispatch)
  }

  // block range with predicate
  range = $from.blockRange(
    $to,
    (node) => node.childCount && node.firstChild.type == itemType
  )

  // First item in sublist, move it to list above, keep the sublist
  return liftToOuterList(state, dispatch, state.schema.nodes.list_item, range)
}

export function dedentList(state, dispatch) {
  const { $from, $to } = state.selection

  if (!inListItem(state)) {
    return false
  }

  return dedentListHelper(state, dispatch, $from, $to)
}

export function liftToOuterList(state, dispatch, itemType, range) {
  let tr = state.tr,
    end = range.end,
    endOfList = range.$to.end(range.depth)
  if (end < endOfList) {
    // There are siblings after the lifted items, which must become
    // children of the last item
    tr.step(
      new ReplaceAroundStep(
        end - 1,
        endOfList,
        new Slice(
          Fragment.from(itemType.create(null, range.parent.copy())),
          1,
          0
        ),
        1,
        true
      )
    )
    range = new NodeRange(
      tr.doc.resolve(range.$from.pos),
      tr.doc.resolve(endOfList),
      range.depth
    )
  }

  dispatch(tr.lift(range, liftTarget(range)).scrollIntoView())

  return true
}

export function liftOutOfList(state, dispatch, range) {
  let tr = state.tr,
    list = range.parent

  // Merge the list items into a single big item
  for (
    let pos = range.end, i = range.endIndex - 1, e = range.startIndex;
    i > e;
    i--
  ) {
    pos -= list.child(i).nodeSize
    tr.delete(pos - 1, pos + 1)
  }

  let $start = tr.doc.resolve(range.start),
    item = $start.nodeAfter
  if (tr.mapping.map(range.end) != range.start + $start.nodeAfter.nodeSize)
    return false
  let atStart = range.startIndex == 0,
    atEnd = range.endIndex == list.childCount
  let parent = $start.node(-1),
    indexBefore = $start.index(-1)
  if (
    !parent.canReplace(
      indexBefore + (atStart ? 0 : 1),
      indexBefore + 1,
      item.content.append(atEnd ? Fragment.empty : Fragment.from(list))
    )
  )
    return false
  let start = $start.pos,
    end = start + item.nodeSize
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(
    new ReplaceAroundStep(
      start - (atStart ? 1 : 0),
      end + (atEnd ? 1 : 0),
      start + 1,
      end - 1,
      new Slice(
        (atStart
          ? Fragment.empty
          : Fragment.from(list.copy(Fragment.empty))
        ).append(
          atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))
        ),
        atStart ? 0 : 1,
        atEnd ? 0 : 1
      ),
      atStart ? 0 : 1
    )
  )
  dispatch(tr.scrollIntoView())
  return true
}

export const selectionContainsList = (tr) => {
  const {
    selection: { from, to },
  } = tr
  let foundListNode = null
  tr.doc.nodesBetween(from, to, (node) => {
    if (isListNode(node)) {
      foundListNode = node
    }
    if (foundListNode) {
      return false
    }
    return true
  })
  return foundListNode
}

function modifiedLiftListItem(selection, tr) {
  let { $from, $to } = selection
  const nodeType = tr.doc.type.schema.nodes.list_item

  let range = $from.blockRange(
    $to,
    (node) =>
      !!node.childCount &&
      !!node.firstChild &&
      node.firstChild.type === nodeType
  )

  if (
    !range ||
    range.depth < 2 ||
    $from.node(range.depth - 1).type !== nodeType
  ) {
    return tr
  }
  let end = range.end
  let endOfList = $to.end(range.depth)

  if (end < endOfList) {
    tr.step(
      new ReplaceAroundStep(
        end - 1,
        endOfList,
        end,
        endOfList,
        new Slice(
          Fragment.from(nodeType.create(undefined, range.parent.copy())),
          1,
          0
        ),
        1,
        true
      )
    )

    range = new NodeRange(
      tr.doc.resolve($from.pos),
      tr.doc.resolve(endOfList),
      range.depth
    )
  }

  return tr.lift(range, liftTarget(range)).scrollIntoView()
}

export const getListLiftTarget = (resPos) => {
  let target = resPos.depth
  for (let i = resPos.depth; i > 0; i--) {
    const node = resPos.node(i)
    if (isListNode(node)) {
      target = i
    }
    if (!isListItemNode(node) && !isListNode(node)) {
      break
    }
  }
  return target - 1
}

export function liftSelectionList(selection, tr) {
  const { from, to } = selection
  const { paragraph } = tr.doc.type.schema.nodes
  const listCol = []
  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (node.type === paragraph) {
      listCol.push({ node, pos })
    }
  })
  for (let i = listCol.length - 1; i >= 0; i--) {
    const paragraph = listCol[i]
    const start = tr.doc.resolve(tr.mapping.map(paragraph.pos))
    if (start.depth > 0) {
      let end
      if (paragraph.node.textContent && paragraph.node.textContent.length > 0) {
        end = tr.doc.resolve(
          tr.mapping.map(paragraph.pos + paragraph.node.textContent.length)
        )
      } else {
        end = tr.doc.resolve(tr.mapping.map(paragraph.pos + 1))
      }
      const range = start.blockRange(end)

      if (range) {
        tr.lift(range, getListLiftTarget(start))
      }
    }
  }
  return tr
}

export function liftFollowingList(from, to, rootListDepth, tr) {
  const { list_item } = tr.doc.type.schema.nodes
  let lifted = false
  tr.doc.nodesBetween(from, to, (node, pos) => {
    if (!lifted && node.type === list_item && pos > from) {
      lifted = true
      let listDepth = rootListDepth + 3
      while (listDepth > rootListDepth + 2) {
        const start = tr.doc.resolve(tr.mapping.map(pos))
        listDepth = start.depth
        const end = tr.doc.resolve(
          tr.mapping.map(pos + node.textContent.length)
        )
        const sel = new TextSelection(start, end)
        tr = modifiedLiftListItem(sel, tr)
      }
    }
  })
  return tr
}

export const rootListDepth = (pos, nodes) => {
  const { bullet_list, ordered_list, list_item } = nodes
  let depth

  for (let i = pos.depth - 1; i > 0; i--) {
    const node = pos.node(i)
    if (node.type === bullet_list || node.type === ordered_list) {
      depth = i
    }
    if (
      node.type !== bullet_list &&
      node.type !== ordered_list &&
      node.type !== list_item
    ) {
      break
    }
  }
  return depth
}

function untoggleSelectedList(tr) {
  const { selection } = tr
  const depth = rootListDepth(selection.$to, tr.doc.type.schema.nodes)

  tr = liftFollowingList(
    selection.$to.pos,
    selection.$to.end(depth),
    depth || 0,
    tr
  )
  tr = liftSelectionList(selection, tr)
}

export function currentListContainer(view) {
  const { state } = view
  const { selection, schema } = state
  let depth = selection.$from.depth
  let node
  let current = null

  // Find list that's has the hightest depth
  while (depth > 0) {
    node = selection.$from.node(depth)
    if (
      node.type === schema.nodes.ordered_list ||
      node.type === schema.nodes.bullet_list
    ) {
      current = {
        node,
        start: selection.$from.before(depth),
        end: selection.$from.after(depth),
      }
    }
    depth -= 1
  }

  return current
}

export function toggleList(listType, attrs) {
  return (state, dispatch) => {
    let tr = state.tr
    const listInsideSelection = selectionContainsList(tr)
    const listNodeType = state.schema.nodes[listType]
    if (listInsideSelection) {
      const { selection } = state
      const fromNode = selection.$from.node(selection.$from.depth - 2)
      const toNode = selection.$to.node(selection.$to.depth - 2)

      if (fromNode.type.name === listType && toNode.type.name === listType) {
        let tr = state.tr
        untoggleSelectedList(tr)

        if (dispatch) {
          dispatch(tr)
        }

        return true
      }
    } else {
      const replaceCurrentTr = (_tr) => {
        tr = _tr
      }
      wrapInList(listNodeType, attrs)(state, replaceCurrentTr)
    }

    // if document wasn't changed, that means setNodeMarkup step didn't work, so
    // return false from the command to indicate that the editing action failed
    if (!tr.docChanged) {
      return false
    }

    if (dispatch) {
      dispatch(tr)
    }

    return true
  }
}

export function convertList(listType, currentList, attrs) {
  return (state, dispatch) => {
    // This updates the
    const tr = state.tr

    // All decendents that are the other type
    const otherListType =
      listType == 'bullet_list'
        ? state.schema.nodes.ordered_list
        : state.schema.nodes.bullet_list

    // Traverse the document
    state.doc.nodesBetween(
      currentList.start,
      currentList.start + currentList.node.nodeSize,
      (node, pos) => {
        if (node.type == otherListType && node != currentList.node) {
          tr.setNodeMarkup(pos, state.schema.nodes[listType], node.attrs || {})
        }
      }
    )

    // Change the top level list
    tr.setNodeMarkup(currentList.start, state.schema.nodes[listType], attrs)

    dispatch(tr)
  }
}

export function joinUpListNodesCorrectly(state, dispatch) {
  const { selection } = state
  const { $from, $to } = selection
  const { list_item } = state.schema.nodes

  const grandParent = $from.node(-1)
  if ((grandParent && grandParent.type != list_item) || $from != $to) {
    return false
  }

  if (
    $from.node().childCount == 0 ||
    String($from.node().textContent).match(/^\s*$/)
  ) {
    joinBackward(state, dispatch)
  }

  return false
}

export function handleListNodesWithOnlyBreaks(state, dispatch) {
  const { selection } = state
  const { $from, $to } = selection
  const { list_item, hard_break } = state.schema.nodes

  const grandParent = $from.node(-1)
  const tr = state.tr

  if ((grandParent && grandParent.type != list_item) || $from != $to) {
    return false
  }

  const hardBreaksOrBlankStrings = []
  $from.node().forEach((node) => {
    hardBreaksOrBlankStrings.push(
      node.type === hard_break ||
        (node.type.name == 'text' && String(node.text).match(/^\s*$/))
    )
  })

  if (
    grandParent.type == list_item &&
    hardBreaksOrBlankStrings.every((item) => !!item)
  ) {
    tr.delete($from.start(), $from.end())
    if (dispatch) {
      dispatch(tr)
    }
  }
  // Continue with the chain
  return false
}
