const EVENT = 'ProseMirror'
const TRACKING_ENABLED = true

export function track(action, otherAttributes = {}) {
  try {
    if (typeof window.top.gtag != 'undefined' && TRACKING_ENABLED) {
      window.top.gtag('event', EVENT, { action, ...otherAttributes })
    }
  } catch (e) {
    // Ignore any errors
  }
}

export function withTracking(action, attributes = {}, fn) {
  return (...args) => {
    track(action, attributes)
    return fn(...args)
  }
}
