import crel from 'crelt'
import { MenuItem } from 'prosemirror-menu'
import { fontAwesomeIcon } from '../utils'
const CLICK_EVENTS = [
  'pointerdown',
  'mousedown',
  'pointerup',
  'mouseup',
  'click',
]

// Search within react component won't work without this hack
function stopPropagationOfClickEvents(el) {
  for (const k of CLICK_EVENTS) {
    el.addEventListener(k, (e) => {
      e.stopImmediatePropagation()
    })
  }
}

export function emojiMenuItem(_schema, _options) {
  return new MenuItem({
    icon: fontAwesomeIcon('face-smile-plus').dom,
    title: 'Emoji',
    class: 'prosemirror-emoji',
    render(view) {
      const menuItem = crel('div', {}, fontAwesomeIcon('face-smile-plus').dom)
      const emojifyContainer = crel('div', {})

      const onSelectEmoji = (emoji) => {
        view.dispatch(
          view.state.tr.replaceSelectionWith(view.state.schema.text(emoji))
        )
      }

      const importPromise = import('../../../react/components/emoji_picker')

      // Wait until the DOM is ready
      setTimeout(() => {
        importPromise.then(({ installEmojiPicker }) =>
          installEmojiPicker.call(emojifyContainer, {
            onSelectCallback: onSelectEmoji,
            showOnClickOf: menuItem,
          })
        )
      }, 300)

      const container = crel('div', {}, [menuItem, emojifyContainer])

      stopPropagationOfClickEvents(container)

      return container
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    run() {},
  })
}
