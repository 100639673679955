import { Plugin, PluginKey, TextSelection } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

const DEFAULT_ID = 'linkmark'
const MAX_MATCH = 100
const pluginKey = new PluginKey(DEFAULT_ID)

function getMarkType(view, opts) {
  if ('schema' in view) return opts.markType
  return opts.markType || view.state.schema.marks.link
}

function safeResolve(doc, pos) {
  return doc.resolve(Math.min(Math.max(1, pos), doc.nodeSize - 2))
}

function stepOutsideNextTrAndPass(view, plugin, action) {
  const meta = { action }
  view.dispatch(view.state.tr.setMeta(plugin, meta))
  return false
}

export function onBacktick(view, plugin, event, markType) {
  if (view.state.selection.empty) return false
  if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey)
    return false
  // Create a link mark!
  const { from, to } = view.state.selection
  if (to - from >= MAX_MATCH || view.state.doc.rangeHasMark(from, to, markType))
    return false
  const tr = view.state.tr.addMark(from, to, markType.create())
  const selected = tr
    .setSelection(TextSelection.create(tr.doc, to))
    .removeStoredMark(markType)
  view.dispatch(selected)
  return true
}

function onArrowRightInside(view, plugin, event, markType) {
  if (event.shiftKey || event.altKey || event.ctrlKey) return false
  const { selection, doc } = view.state
  if (!selection.empty) return false
  const pluginState = plugin.getState(view.state) || {}
  const pos = selection.$from
  const inlink = !!markType.isInSet(pos.marks())
  const nextlink = !!markType.isInSet(
    pos.marksAcross(safeResolve(doc, selection.from + 1)) || []
  )
  const mark = (pos.marksAcross(safeResolve(doc, selection.from + 1)) || [])[0]
  const cursorAtEdge =
    inlink &&
    (!pluginState.active || pluginState.side === -1) &&
    pos.parentOffset !== 0

  if (event.metaKey && !cursorAtEdge)
    return stepOutsideNextTrAndPass(view, plugin)

  if (
    pos.pos === view.state.doc.nodeSize - 3 &&
    pos.parentOffset === pos.parent.nodeSize - 2 &&
    pluginState.active
  ) {
    // Behaviour stops: `link`| at the end of the document
    view.dispatch(view.state.tr.removeStoredMark(markType))
    return true
  }

  if (inlink === nextlink && pos.parentOffset !== 0) {
    return false
  }

  if (cursorAtEdge) {
    // `link|` --> `link`|
    view.dispatch(view.state.tr.removeStoredMark(markType))

    if (event.metaKey) {
      return stepOutsideNextTrAndPass(view, plugin)
    }

    return true
  }
  if (nextlink && pluginState && pluginState.side === -1) {
    // |`link` --> `|link`
    view.dispatch(
      view.state.tr.addStoredMark(markType.create({ ...mark.attrs }))
    )
    return true
  }
  return false
}

export function onArrowRight(view, plugin, event, markType) {
  const handled = onArrowRightInside(view, plugin, event, markType)

  if (handled) return true
  const { selection } = view.state
  const pos = selection.$from

  if (selection.empty && pos.parentOffset === pos.parent.nodeSize - 2) {
    return stepOutsideNextTrAndPass(view, plugin)
  }

  return false
}

function onArrowLeftInside(view, plugin, event, markType) {
  if (event.metaKey) return stepOutsideNextTrAndPass(view, plugin)
  if (event.shiftKey || event.altKey || event.ctrlKey) return false
  const { selection, doc } = view.state
  const pluginState = plugin.getState(view.state) || {}
  const inlink = !!markType.isInSet(selection.$from.marks())
  const nextlink = !!markType.isInSet(
    safeResolve(
      doc,
      selection.empty ? selection.from - 1 : selection.from + 1
    ).marks() || []
  )

  const mark = (selection.$from.marks() || [])[0]

  if (
    inlink &&
    pluginState &&
    pluginState.side === -1 &&
    selection.$from.parentOffset === 0
  ) {
    // New line!
    // ^|`link` --> |^`link`
    return false
  }
  if (
    pluginState &&
    pluginState.side === 0 &&
    selection.$from.parentOffset === 0
  ) {
    // New line!
    // ^`|link` --> ^|`link`
    view.dispatch(view.state.tr.removeStoredMark(markType))
    return true
  }
  if (inlink && nextlink && pluginState && pluginState.side === 0) {
    // `link`| --> `link|`
    view.dispatch(
      view.state.tr.addStoredMark(markType.create({ ...mark.attrs }))
    )
    return true
  }
  if (
    inlink &&
    !nextlink &&
    pluginState &&
    pluginState.active &&
    selection.$from.parentOffset === 0
  ) {
    // ^`|link` --> ^|`link`
    view.dispatch(view.state.tr.removeStoredMark(markType))
    return true
  }
  if (!inlink && pluginState && pluginState.active && pluginState.side === 0) {
    // `|link` --> |`link`
    view.dispatch(view.state.tr.removeStoredMark(markType))
    return true
  }
  if (inlink === nextlink) return false
  if (nextlink || (!selection.empty && inlink)) {
    // `link`_|_ --> `link`|   nextlink
    // `link`███ --> `link`|   !selection.empty && inlink
    // `██de`___ --> `|link`   !selection.empty && nextlink
    const from = selection.empty ? selection.from - 1 : selection.from
    const selected = view.state.tr.setSelection(TextSelection.create(doc, from))
    if (!selection.empty && nextlink) {
      view.dispatch(
        selected.addStoredMark(markType.create({ ...nextlink.attrs }))
      )
    } else {
      view.dispatch(selected.removeStoredMark(markType))
    }
    return true
  }
  if ((nextlink || (!selection.empty && inlink)) && !pluginState.active) {
    // `link`_|_ --> `link`|
    // `link`███ --> `link`|
    const from = selection.empty ? selection.from - 1 : selection.from
    view.dispatch(
      view.state.tr
        .setSelection(TextSelection.create(doc, from))
        .removeStoredMark(markType)
    )
    return true
  }
  if (inlink && !pluginState.active && selection.$from.parentOffset > 0) {
    // `c|ode` --> `|link`
    view.dispatch(
      view.state.tr
        .setSelection(TextSelection.create(doc, selection.from - 1))
        .addStoredMark(markType.create({ ...mark.attrs }))
    )
    return true
  }
  if (inlink && !nextlink && pluginState.active && pluginState.side !== -1) {
    // `x`| --> `x|` - Single character
    view.dispatch(view.state.tr.addStoredMark(markType.create(mark.attrs)))
    return true
  }
  if (inlink && !nextlink && pluginState.active) {
    // `x|` --> `|x` - Single character inside
    const pos = selection.from - 1
    view.dispatch(
      view.state.tr
        .setSelection(TextSelection.create(doc, pos))
        .addStoredMark(markType.create(mark.attrs))
    )
    return true
  }
  return false
}

export function onArrowLeft(view, plugin, event, markType) {
  const handled = onArrowLeftInside(view, plugin, event, markType)
  if (handled) return true
  const { selection } = view.state
  const pos = selection.$from
  const pluginState = plugin.getState(view.state)
  if (pos.pos === 1 && pos.parentOffset === 0 && pluginState.side === -1) {
    return true
  }
  if (selection.empty && pos.parentOffset === 0) {
    return stepOutsideNextTrAndPass(view, plugin)
  }
  return false
}

export function onBackspace(view, plugin, event, markType) {
  if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey)
    return false
  const { selection, doc } = view.state
  const from = safeResolve(doc, selection.from - 1)
  const fromlink = !!markType.isInSet(from.marks())
  const startOfLine = from.parentOffset === 0
  const tolink = !!markType.isInSet(safeResolve(doc, selection.to + 1).marks())
  if ((!fromlink || startOfLine) && !tolink) {
    // `x|`    → |
    // `|████` → |
    // `|███`█ → |
    return stepOutsideNextTrAndPass(view, plugin)
  }
  // Firefox has difficulty with the decorations on -1.
  const pluginState = plugin.getState(view.state) || {}
  if (selection.empty && pluginState.side === -1) {
    const tr = view.state.tr.delete(selection.from - 1, selection.from)
    view.dispatch(tr)
    return true
  }
  return false
}

export function onDelete(view, plugin, event, markType) {
  if (event.metaKey || event.shiftKey || event.altKey || event.ctrlKey)
    return false
  const { selection, doc } = view.state
  const fromlink = !!markType.isInSet(selection.$from.marks())
  const startOfLine = selection.$from.parentOffset === 0
  const tolink = !!markType.isInSet(safeResolve(doc, selection.to + 2).marks())
  if ((!fromlink || startOfLine) && !tolink) {
    return stepOutsideNextTrAndPass(view, plugin)
  }
  return false
}

export function stepOutside(state, markType) {
  if (!state) return null
  const { selection, doc } = state
  if (!selection.empty) return null
  const stored = !!markType.isInSet(state.storedMarks || [])
  const inlink = !!markType.isInSet(selection.$from.marks())
  const nextlink = !!markType.isInSet(
    safeResolve(doc, selection.from + 1).marks() || []
  )
  const startOfLine = selection.$from.parentOffset === 0
  // `link|` --> `link`|
  // `|link` --> |`link`
  // ^`|link` --> ^|`link`
  if (
    inlink !== nextlink ||
    (!inlink && stored !== inlink) ||
    (inlink && startOfLine)
  )
    return state.tr.removeStoredMark(markType)
  return null
}

function toDom() {
  const span = document.createElement('span')
  span.classList.add('fake-cursor')
  return span
}

function getDecorationPlugin(opts) {
  const plugin = new Plugin({
    key: pluginKey,
    view() {
      return {
        update: (view) => {
          const state = plugin.getState(view.state)
          view.dom.classList[state && state.active ? 'add' : 'remove'](
            'no-cursor'
          )
        },
      }
    },
    appendTransaction: (trs, oldState, newState) => {
      const prev = plugin.getState(oldState)
      const meta = trs[0].getMeta(plugin)

      if ((prev && prev.next) || (meta && meta.action === 'click')) {
        return stepOutside(newState, getMarkType(newState, opts))
      }
      return null
    },
    state: {
      init: () => null,
      apply(tr, value, oldState, state) {
        const meta = tr.getMeta(plugin)
        if (meta && meta.action === 'next') return { next: true }

        const markType = getMarkType(state, opts)
        const nextMark = markType.isInSet(
          state.storedMarks || state.doc.resolve(tr.selection.from).marks()
        )
        const inlink = markType.isInSet(
          state.doc.resolve(tr.selection.from).marks()
        )
        const nextlink = markType.isInSet(
          safeResolve(state.doc, tr.selection.from + 1).marks()
        )

        const startOfLine = tr.selection.$from.parentOffset === 0
        if (!tr.selection.empty) return null

        if (!nextMark && nextlink && (!inlink || startOfLine)) {
          // |`link`
          return { active: true, side: -1 }
        }
        if (nextMark && (!inlink || startOfLine)) {
          // `|link`
          return { active: true, side: 0 }
        }
        if (!nextMark && inlink && !nextlink) {
          // `link`|
          return { active: true, side: 0 }
        }
        if (nextMark && inlink && !nextlink) {
          // `link|`
          return { active: true, side: -1 }
        }
        return null
      },
    },
    props: {
      decorations: (state) => {
        const { active, side } = plugin.getState(state) || {}
        if (!active) return DecorationSet.empty
        const deco = Decoration.widget(state.selection.from, toDom, { side })
        return DecorationSet.create(state.doc, [deco])
      },
      handleKeyDown(view, event) {
        switch (event.key) {
          case 'ArrowRight':
            return onArrowRight(view, plugin, event, getMarkType(view, opts))
          case 'ArrowLeft':
            return onArrowLeft(view, plugin, event, getMarkType(view, opts))
          case 'Backspace':
            return onBackspace(view, plugin, event, getMarkType(view, opts))
          case 'Delete':
            return onDelete(view, plugin, event, getMarkType(view, opts))
          case 'ArrowUp':
          case 'ArrowDown':
          case 'Home':
          case 'End':
            return stepOutsideNextTrAndPass(view, plugin)
          case 'e':
          case 'a':
            if (!event.ctrlKey) return false
            return stepOutsideNextTrAndPass(view, plugin)
          default:
            return false
        }
      },
      handleClick(view) {
        return stepOutsideNextTrAndPass(view, plugin, 'click')
      },
    },
  })
  return plugin
}

export function linkCursorPlugin(opts) {
  return getDecorationPlugin(opts)
}
